function Input({
    label,
    placeholder,
    value,
    inputType,
    onChange,
}) {
    return <div className="flex flex-col w-full">
        <label className="label">
            <span className="label-text">{label}</span>
        </label>
        <input
            defaultValue={value}
            type={inputType}
            onChange={({ target: { value } }) => onChange(value)}
            placeholder={placeholder || label}
            className="input input-bordered w-full bg-base-content placeholder-black text-black"
        />
    </div>
}
export default Input;
