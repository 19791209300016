import { toast } from 'react-toastify';
import {
  useParams,
  useHistory
} from "react-router-dom";
import create from 'zustand';
import { persist } from 'zustand/middleware';
import Flow from '@las-drop-tools/el-flow';
import { useApi } from '../../el-api';
import { createArtist } from './flows';

export const useArtist = create(persist(
  (set, get) => ({
  artist: { answers: [], errors: [] },
  setArtist: (artist) => set((state) => ({
    ...state,
    artist: {
      answers: {
        ...state.artist.answers,
        ...artist.answers
      },
      errors: {
        ...state.artist.errors,
        ...artist.errors
      }
    }
  }))
}),
{
  name: 'artist'
}));

export default () => {
  const api = useApi();
  const { setArtist, artist} = useArtist();
  const history = useHistory();
  
  // to do move to own hook
  const params = useParams();
  let step = createArtist.steps.find((step) => step?.id === params?.stepId);
  if (!step?.id) {
    step = createArtist.steps[0]
  }

  const create = (data) => api.create('artist', {
    data
  });

  const toastOptions = {
    theme: 'dark',
    position: 'bottom-right'
  }

  return (
    <>
      <Flow
        flow={createArtist}
        initialData={{
          genres: ["whatever"]
        }}
        step={step}
        flowData={artist}
        onUpdate={(data) => setArtist(data)}
        onStepChange={({ step, answers, errors }) => { history.push('/artists/create/'+step.id) }}
        actions={{
          create,
          alert: (content)=> toast(content, toastOptions),
        }}
      />
    </>
  )

}