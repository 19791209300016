

export default function Button ({
    onClick,
    copy,
    color,
    className = "",
    disabled,
    children
}){
   return <button disabled={disabled} className={ `btn ${color ? 'btn-'+color : ''} ${className}` } onClick={onClick}>{copy || children}</button>
}