import { api as makeApi } from '../../el-api';
const assetsApi = makeApi('asset');

export const uploadCover = {
  id: 'uploadCover',
  fields: [
    {
      type: 'file',
      key: 'cover',
      sourceAssets: async () => {
        const {data} = await assetsApi.findAll();
        return data;
      }
    },
    {
      type: 'text',
      copy: 'Selecciona o sube una foto de portada para tu evento',
      alignment: 'center',
      size: '2xl'
    },
    {
      type: 'input',
      placeholder: 'Teteo 2069, Dropfest 2023, etc.',
      key: 'title'
    }
  ]
};

export const generalInfo = {
  id: 'generalInfo',
  fields: [
    {
      type: 'text',
      copy: 'Ingresa los datos de tu evento',
      alignment: 'center',
      size: '2xl'
    },
    {
      type: 'input',
      label: 'Descripción del evento',
      key: 'description',
      inputType: 'text',
      // props: {},
      // validation: {
      //   condition: () => true,
      //   message: 'Is required.'
      // }
    },
    {
      type: 'row',
      key: 'group',
      fields: [
        {
          type: 'input',
          inputType: 'date',
          label: 'Fecha del evento',
          key: 'date',
          props: {},
          validation: {
            condition: () => true,
            message: 'Is required.'
          }
        },
        {
          type: 'input',
          label: 'Nombre del lugar',
          key: 'placeName',
          inputType: 'text',
          props: {},
          validation: {
            condition: () => true,
            message: 'Is required.'
          }
        },
      ]
    },
    {
      type: 'text',
      copy: 'Selecciona en el mapa la ubicación de tu evento',
      alignment: 'center',
      size: '2xl'
    },
    {
      type: 'locationPicker',
      key: 'place'
    },

    {
      type: 'text',
      copy: 'Puedes usar el buscador para encontrar la ubicación más fácil',
      alignment: 'center',
      size: 'lg'
    },
  ]
}

export const content = {
  fields: [
    {
      type: 'text',
      key: 'content.Title',
      textType: 'h1',
      copy: 'Usa el editor 👇 para agregar una descripción a tu evento',
      size: 'xl',
      alignment: 'center',
    },
    {
      type: 'editor',
      key: 'content',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      },
    },
      {
        type: 'button',
        key: 'button',
        onClick: ({actions, answers}) => {
          actions.submit({ actions, answers });
        }
    },
    
  ]
}

export const tickets = {
  id: 'tickets',
  fields: [
    {
      type: 'tickets',
      key: 'tickets',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    }
  ]
}

export const success = {
  id: 'success',
  fields: [
    {
      type: 'confetti',
      key: 'confetti',
      active: (answers, errors) => true,
    },
    {
      type: 'hero',
      title: 'Tu evento se ha creado exitosamente',
      content: `
        Recuerda que puedes actualizar la info de tu evento en cualquier momento.
      `,
      button: {
        copy: 'Ir a mi nuevo evento',
        onClick: ({ actions }) => actions.goTo('/events')
      },
      image: 'success',
      key: 'thanksHero',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
  ]
}

export default {
  uploadCover,
  generalInfo,
  content,
  tickets,
  success,
}