import { useState } from 'react';
import Form from '@las-drop-tools/el-form';
import { api as makeApi } from '../../el-api';
import { toast } from 'react-toastify';


const InvitationsTable = ({
    events,
    setEvents,
    selectedEvent,
}) => {
    const [invitations, setInvitations] = useState({ artists: [] });
    return (
        <dialog id="eventInvitesModal" className="modal text-white">
            <form method="dialog" className="modal-box max-w-none border-2">
                <h3 className="font-bold text-lg">Ingresa los nombres de los invitados:</h3>
                <Form
                    actions={
                        {
                            upsertArtist: (artist) => makeApi('artist').upsert({
                                where: artist,
                                update: artist,
                                create: artist
                            })
                        }
                    }
                    style={{ zIndex: 1000 }}
                    form={{
                        fields: [
                            {
                                type: 'select-multi',
                                label: 'Selecciona los artistas relacionados',
                                key: 'artists',
                                sourceOptions: async (actions) => {
                                    const { data } = await makeApi('artist').findAll();
                                    return data.reduce((acc, artist) => {
                                        const hasInvitation = events?.find((event) => event.id === selectedEvent.id)?.invitations?.find((invitation) => invitation.name === artist.handle);
                                        if (!hasInvitation) {
                                            acc.push({
                                                label: artist?.handle, value: artist?.id
                                            });
                                        }
                                        return acc;
                                    }, []);
                                },
                                onCreate: async (actions, artist) => {
                                    const { data } = await actions.upsertArtist({ handle: artist.startsWith('@') ? artist : `@${artist}` });
                                    return {
                                        label: data?.handle, value: data?.id
                                    };
                                },
                            },
                        ]
                    }}
                    onChange={(formdata) => {
                        setInvitations(formdata.answers);
                    }}
                    answers={invitations}
                />
                <div className="overflow-x-auto overflow-y-auto max-h-72 z-1">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                <th>Invitado</th>
                                <th>Enlace</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events && events.find(event => event.id === selectedEvent?.id)?.invitations?.map((invitation) =>
                                <tr>
                                    <td>{invitation.name}</td>
                                    {!invitation.accepted ? <td className='link link-primary color-primary cursor-pointer' onClick={() => { navigator.clipboard.writeText(`entradas.eldrop.club/eventos/invitacion/${invitation.id}`); toast('Enlace copiado') }}>Copiar Enlace</td> : <td className='disabled cursor-not-allowed'>Enlace Expirado</td>}
                                    <td>{invitation.accepted ? 'Aceptada' : 'Pendiente'}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="modal-action">
                    <button className="btn">Close</button>
                    <button className="btn btn-primary" onClick={() => {
                        const mappedData = invitations?.artists.map((invitation) => ({
                            name: invitation.label,
                            eventId: selectedEvent.id,
                        }));
                        makeApi('eventInvitation').create({
                            data: mappedData.length > 1 ? mappedData : mappedData[0],
                            // skipDuplicates: true,
                        }).then(({ data }) => {
                            setEvents(events.map((event) => event.id === selectedEvent.id ? { ...event, invitations: [...event?.invitations, ...invitations.artists.map((artist) => { return { name: artist?.label } })] } : event));
                            setInvitations({ artists: [] });
                            toast.success('Invitations created successfully');
                        }).catch((err) => {
                            toast.error(err.message);
                        });
                    }}>Crear</button>
                </div>
            </form>
        </dialog>
    )
}

export default InvitationsTable;