import qs from 'qs';
import create from 'zustand';
const BASE_URL = '/api';

const request = ({
    query, body, method, endpoint, scope = 'root'
}) => {
    return new Promise((resolve, reject) => {
        const url = `${BASE_URL}/${scope === 'root' ? '' : '/'+scope+'/'}${endpoint}${query ? '?' + qs.stringify(query, { encode: false }) : ''}`;
        fetch(url, {
            method,
            ...(body && { body: JSON.stringify(body) })
        })
        .then(response => response.json())
            .then(json => resolve(json))
            .catch(err => reject(err))
    });
}

export const api = (entity, scope = 'root') => ({
    findAll: (query = {}) => request({
        method: 'GET',
        query,
        endpoint: entity,
        scope,
    }),
    findOne: (id, query) => request({
        method: 'GET',
        query,
        endpoint: `${entity}/${id}`,
        scope,
    }),
    create: (body) => request({
        method: 'POST',
        body,
        endpoint: entity,
        scope,
    }),
    upsert: (body) => request({
        method: 'PATCH',
        body,
        endpoint: entity,
        scope,
    }),
    update: (id, body, query) => request({
        method: 'PUT',
        body,
        query,
        endpoint: `${entity}/${id}`,
        scope,
    }),
    destroy: (id) => request({
        method: 'DELETE',
        endpoint: `${entity}/${id}`,
        scope,
    }),
})

export const useApi = create((set, get) => ({
    findAll: async (entity, query) => {
        set((state) => ({
            loading: true,
        }));
        const { data } = await api(entity).findAll(query);
        set((state) => ({
            [entity]: {
                ...state?.[entity],
                collection: data
            },
            loading: false
        }))
    },
    findOne: async (entity, id, query) => {
        set((state) => ({
            loading: true,
        }));
        const { data } = await api(entity).findOne(id, query);
        set((state) => ({
            [entity]: {
                ...state?.[entity],
                [id]: data[0],
            },
            loading: false
        }));
    },
    create: async (entity, body) => {
        set((state) => ({
            loading: true,
        }));
        const { data } = await api(entity).create(body);
        set((state) => ({
            [entity]: {
                ...state?.[entity],
                collection: state?.[entity]?.collection?.length ? [
                    ...state?.[entity].collection,
                    data
                ] : [data],
                lastCreated: data,
            },
            loading: false
        }))
    },
    update: async (entity, id, body) => {
        set((state) => ({
            loading: true,
        }));
        const { data } = await api(entity).update(id, body);
        set((state) => ({
            [entity]: {
                ...state?.[entity],
                collection: state?.[entity]?.collection?.length ? state?.[entity].collection.map((record) => record.id === data.id ? data : record) : [data],
                [data?.id]: data,
                lastUpdated: data,
            },
            loading: false
        }))
    },
    destroy: async (entity, id) => {
        set((state) => ({
            loading: true,
        }));
        const { data } = await api(entity).destroy(id);
        set((state) => ({
            loading: false,
            [entity]: {
                ...state?.[entity],
                collection: state?.[entity]?.collection?.length ? state?.[entity].collection.filter((record) => record.id !== id) : [],
                loading: false,
                lastDeleted: data,
            }
        }))
    },
    loading: false,
}));
