import React from 'react';
import { assets } from '@las-drop-tools/los-componentes'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <div className="w-full h-full flex flex-col justify-center items-center space-y-8">
          <img src={assets.map} alt="not found" className='w-60' />
          <h1>¡Oops! Algo salió mal</h1>
          {this.state.error && this.state.error.toString()}
          <button onClick={()=> this.props.onAction()}>Go back</button>
        </div>
        )
      }
  
      return this.props.children; 
    }
  }