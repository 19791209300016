import { addArtist, success } from './forms';

export const createArtist = {
    steps: [
      {
        id: 'upload-cover',
        form: addArtist,
        actions: [

        ]
      },
      {
        id: 'success',
        form: success,
        actions: []
      }, 
    ]
}