import { Icon } from '@las-drop-tools/los-componentes';
const camelCaseToSpaces = (str) => str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());


const Table = ({
    items,
    fields,
    history,
    actions,
    menuItems,
}) => {
    return <>
        <div className="overflow-x-overflow w-full">
            <table className="table w-full my-8">
                <thead>
                    <tr>
                        {items && Object.keys(fields).map((key) => (<th key={key}>{camelCaseToSpaces(key)}</th>))}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {items?.length && items?.sort?.(
                        // sort by createdAt ascending, then by title descending, then by isPublished ascending
                        (a, b) => a.createdAt.localeCompare(b.createdAt) || b.title.localeCompare(a.title)
                    )?.map?.((item, index) => {
                        return (
                            <tr key={item.id}>
                                {item && Object.entries(fields).map(([key, { component: Component, initialValue }]) => {
                                    const value = initialValue ? initialValue(item) : item[key];
                                    return <td className="max-w-xs" key={key}>{value ? <Component value={value} id={item?.id} /> : ''}</td>
                                })}
                                <td>
                                    <div className="dropdown dropdown-end">
                                        <label tabIndex={0} className="btn btn-circle btn-ghost m-1"><Icon name="more_vert" className="text-xl" /></label>
                                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-secondary rounded-box w-52">
                                            {menuItems.map(({ label, action, icon }) => (
                                                <li key={label} onClick={() => action(item)}><a><Icon name={icon} className='text-xl' />{label}</a></li>
                                            ))}
                                            {/* <li onClick={() => actions?.handleEdit(item.id)} ><a><Icon name="edit" className='text-xl' />Edit</a></li>
                                            <li onClick={() => actions?.handleDelete(item.id)}><a><Icon name="delete" className='text-xl' />Delete</a></li> */}
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>
};

export default Table;