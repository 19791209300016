import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormField from './FormField.jsx';

export const getErrors = (fields, answers) => fields.reduce((map, field) => {
  const isValid = field.validation ? field.validation.condition({ value: answers[field?.key], answers }) : true;
  return isValid ? map : {
    ...map,
    [field?.key]: field?.validation?.message
  }
}, {});

function Form({
  form: {
    fields,
    id = Math.floor(Date.now() / 1000),
  },
  // initialData,
  onChange,
  answers,
  actions,
  errors,
  children,
}) {

  const history = useHistory();

  const handleAnswersUpdate = (data) => {
    const updatedAnswers = { ...data };
    const updatedErrors = getErrors(fields, updatedAnswers);
    const newFormData = {
      answers: updatedAnswers,
      errors: updatedErrors,
    }
    onChange && onChange(newFormData)
  }

  return <div key={id} className='w-full h-full justify-center items-center py-8 flex flex-col gap-4'>
    {fields.map((field, index) => {
      const conditionResult = field?.condition?.(answers);
      if (field?.condition && !conditionResult) {
        return null;
      } else {
        return (
          <FormField key={index} field={field} answers={answers} setAnswers={handleAnswersUpdate} error={errors && (errors[field?.key] || null)} actions={{
            goTo: (location) => history.push(location),
            ...(actions ? actions : {})
          }} />
        )
      }
    })}
    {children}
  </div>
}

export default Form;
