import Select from "./Select";

function Year({
    label,
    placeholder,
    selected,
    onChange,
}) {
    const options = [
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 },
        { label: '2027', value: 2027 },
    ]
    return <Select options={options} label={label} placeholder={placeholder} selected={selected} onChange={onChange} />
}
export default Year;
