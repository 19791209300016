import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
} from "@clerk/clerk-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './el-header';
import './App.css';
import 'animate.css';
import Checkout from "./el-checkout/Checkout";
import ErrorBoundary from "./ErrorBoundary";
import Event from './pages/Event'
import CreateArticle from './features/articles/Create'
import ArticlesTable from './features/articles/Read'
import UpdateArticle from './features/articles/Update'

import CreateSong from './features/songs/Create'
import SongsTable from './features/songs/Read'
import UpdateSong from './features/songs/Update'

import UserTickets from './features/events/UserTickets';


import CreateArtist, { useArtist } from "./pages/artists/Create";
import { pages as EventPages } from './features/events'
import ScanTicket from "./pages/ScanTicket";
// import QRCode from "react-qr-code";


// To do: Move to a component of its own.
const Redirect = ({ to }) => {
  const history = useHistory();
  history.push(to);
  return null
};

// https://codesandbox.io/s/tender-morse-ozvo2k?file=/src/CanvasContainer.tsx
function App() {
  return (
    <div className="h-screen">
      <ToastContainer />
      <ClerkProvider frontendApi="clerk.organic.spaniel-21.lcl.dev">
        <ErrorBoundary onAction={() => window.location.replace('/')}>
          <SignedIn>
            <Router>
              <Switch>
                {EventPages?.filter(({isPrivate})=> isPrivate).map?.(({ path, ui, redirectTo, type }) => <Route path={path}>{type === 'redirect' ? <Redirect to={redirectTo} /> : ui}</Route>)}
                <Route path="/news/create">
                  <Header />
                  <CreateArticle />
                </Route>
                <Route path="/news/update/:id">
                  <Header />
                  <UpdateArticle />
                </Route>
                <Route path="/news">
                  <Header />
                  <ArticlesTable />
                </Route>
                <Route path="/songs/create">
                  <Header />
                  <CreateSong />
                </Route>
                <Route path="/songs/update/:id">
                  <Header />
                  <UpdateSong />
                </Route>
                <Route path="/songs">
                  <SongsTable />
                </Route>
                <Route path="/checkout/:stepId">
                  <Header />
                  <Checkout />
                </Route>
                <Route path="/artists/create/:stepId">
                  <Header />
                  <div className="p-4">
                    <CreateArtist entity="artistd" />
                  </div>
                </Route>
                <Route path="/artists/create">
                  <Redirect to="/artists/create/new" />
                </Route>
                <Route path="/">
                  <Redirect to="/tickets" />
                  {/* <audio src="/api/services/elevenlabs" controls autoPlay loop /> */}
                </Route>
               
              </Switch>
            </Router>
          </SignedIn>
          <SignedOut>
            <Router>
              <Switch>
              {EventPages?.filter(({isPublic})=> isPublic).map?.(({ path, ui, redirectTo, type }) => <Route path={path}>{type === 'redirect' ? <Redirect to={redirectTo} /> : ui}</Route>)}
              <Route path="/">
                <RedirectToSignIn />
              </Route>
              </Switch>
            </Router>
          </SignedOut>
        </ErrorBoundary>
      </ClerkProvider>
    </div>
  );
}

export default App;