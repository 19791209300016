import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api as makeApi } from '../../el-api';
import { Icon, IconHeader, Toggle, Table } from '../../los-componentes';
import { toast } from 'react-toastify';
import Canvas from './../articles/canvas';
import fg from './../articles/assets/fg.png';
import bg from './../articles/assets/bg.png';

const ReadSongs = () => {
    const songsApi = makeApi('song');
    const [songs, setSongs] = useState();

    const history = useHistory();

    useEffect(() => {
        songsApi.findAll({
            include: {
                // cover: true,
                artists: true,
            },
        }).then(({ data }) => {
            setSongs(data)
        });
    }, []);

    const fields = {
        id: {
            label: 'ID',
            component: ({ value }) => <span>{value}</span>,
        },
        cover: {
            label: 'Cover',
            component: ({ value: { url } }) => <>
                <div class="max-w-xs m-auto p-2">
                    <img class="w-full object-cover " src={url} alt="Image" />
                </div>
            </>
        },
        title: {
            label: 'Title',
            component: ({ value }) => <span>{value}</span>,
        },
        spotifyURL: {
            label: 'Title',
            component: ({ value }) => <span>{value}</span>,
        },
        youtubeURL: {
            label: 'Title',
            component: ({ value }) => <span>{value}</span>,
        },
        // preview: {
        //     label: 'Preview',
        //     initialValue: ({ title, copy, cover: { url }, generatedTTS, generatedCopy }) => ({ header: title, subheader: copy, url, generatedTTS, generatedCopy }),
        //     component: ({ value }) => {
        //         const template = {
        //             backgroundImageUrl: bg,
        //             foregroundImageUrl: fg,
        //         };
        //         const header = value?.header || 'Header Text';
        //         const subheader = value?.subheader || 'Subheader Text';
        //         const imageUrl = value?.url || 'https://static-cse.canva.com/blob/1064933/1600w-8iSVF3m00jk.jpg';
        //         const audio = value?.generatedTTS;
        //         return <>
        //             <Canvas template={template} header={header} subheader={subheader} imageUrl={imageUrl} />
        //             <p className='w-full max-w-xs whitespace-normal text-center'>{value.generatedCopy}</p>
        //             {audio && <audio controls="controls">
        //                 <source src={`data:audio/wav;base64,${audio}`} />
        //             </audio>}
        //         </>
        //     },
        // },
        // generatedCopy: {
        //     label: 'Generated Copy',
        //     component: ({ value }) => Array.isArray(value) ? <ul className=''>{value?.map((copy) => <li>{copy}</li>)}</ul> : <span>{value}</span>,
        // },
    }

    const handleDelete = (id) => {
        songsApi.destroy(id).then(({ status, error }) => {
            if (status !== 200) {
                toast.error(error);
            } else {
                setSongs(songs.filter((song) => song.id !== id));
                toast.success('Song deleted successfully');
            }
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    const handleEdit = (id) => {
        history.push(`/songs/update/${id}`);
    };

    const handlePublishToggle = (id, checked) => {
        setSongs(songs.map((song) => song.id === id ? { ...song, isPublished: checked } : song));
        songsApi.update(id, { data: { isPublished: checked } }).then(({ status, error }) => {
            if (status !== 200) {
                toast.error(error);
                setSongs(songs.map((song) => song.id === id ? { ...song, isPublished: !checked } : song));
            } else {
                toast.success('Song updated successfully');

            }
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    return (
        <div className='py-8 px-8 lg:px-24 w-full'>
            <h1 className='text-7xl font-bold uppercase py-8'>Gestiona tus canciones</h1>


            <div className="p-4 flex flex-row-reverse ">
                <button className="btn btn-outline" onClick={() => history.push('/songs/create')}>Create Song</button>
            </div>
            <Table
                fields={fields}
                items={songs}
                actions={{
                    handleDelete,
                    handleEdit,
                }}
                menuItems={[{ label: 'Editar', action: handleEdit, icon: 'edit' }, { label: 'Eliminar', action: handleDelete, icon: 'delete' }]}
                />
        </div>
    );

}

export default ReadSongs;