import {
  useParams,
  useHistory
} from "react-router-dom";
import ElFlow from "@las-drop-tools/el-flow";
import { flow } from './config';
import ErrorBoundary from '../ErrorBoundary';

export default function Checkout(
) {
  const params = useParams(); 
  const history = useHistory();
  const step = flow.steps.find((step)=> step?.id === params?.stepId);

  return <ErrorBoundary onAction={()=> history.push('/home')}>
    <div data-theme="el-drop-minimal">
    <ElFlow flow={flow} initialData={{
      useShipping: true,
      useBilling: true,
    }} step={step} onStepChange={({ step: { id }, answers, errors }) => { history.push(`/checkout/${id}`); console.log(answers) }} />
    </div>
  </ErrorBoundary>
}