import useCreateEvent from "./store";
import { api as makeApi } from '../../el-api';
const eventApi = makeApi('event');

export const create = async ({actions}) => {
  let eventCreateFailed;
  const { flowData: { answers } } = useCreateEvent.getState();
  const mappedAnswers = {
    content: answers?.content,
    title: answers?.title,
    description: answers?.description,
    tags: {},
    location: {
      connectOrCreate: {
        where: {
          label: answers.placeName
        },
        create: {
          label: answers.placeName,
          location: answers.place,
          owner: {
            connectOrCreate: {
              where: {
                email: '{{user_email}}',
              },
              create: {
                name: "{{user_name}}",
                email: "{{user_email}}"
              }
            }
          }
        }
      }
    },
    type: {
      connectOrCreate: {
        where: {
          title: 'Event',
        },
        create: {
          title: 'Event'
        }
      }
    },
    owner: {
      connectOrCreate: {
        where: {
          email: '{{user_email}}',
        },
        create: {
          name: '{{user_name}}',
          email: '{{user_email}}',
        }
      }
    },
    cover: {
      connectOrCreate: {
        where: {
          url: answers.cover.cdnUrl,
        },
        create: {
          url: answers.cover.cdnUrl,
          metadata: answers.cover,
          owner: {
            connectOrCreate: {
              where: {
                email: '{{user_email}}',
              },
              create: {
                name: '{{user_name}}',
                email: '{{user_email}}',
              }
            }
          },
        }
      }
    },
    date: new Date(answers.date),
    tickets: answers?.tickets
    // tickets: {
    //   general: {
    //     title: 'General',
    //     price: 100,
    //     quantity: 50,
    //     admits: 1,
    //   },
    //   cuates: {
    //     title: 'Cuates',
    //     description: 'Se entregarán 4 QRs independientes :)',
    //     price: 300,
    //     quantity: 50,
    //     admits: 4
    //   }
    // }
  }
  try {
    const createdEvent = await eventApi.create({data: mappedAnswers});
    if(createdEvent.status !== 200){
      throw new Error('Failed to create event');
    }
    actions.success();
    actions.goToStep('success');
  } catch (err) {
    eventCreateFailed = true;
  }
  if (eventCreateFailed) {
    actions?.error('Failed');
  }
}

export const update = async ({actions, answers}) => {
  let eventUpdateFailed;
  const mappedAnswers = {
    content: answers?.content,
    title: answers?.title,
    description: answers?.description,
    tags: {},
    location: {
      // set: [],
      connectOrCreate: {
        where: {
          label: answers.placeName
        },
        create: {
          label: answers.placeName,
          location: answers.place,
          owner: {
            connectOrCreate: {
              where: {
                email: '{{user_email}}',
              },
              create: {
                name: "{{user_name}}",
                email: "{{user_email}}"
              }
            }
          }
        }
      }
    },
    type: {
      // set: [],
      connectOrCreate: {
        where: {
          title: 'Event',
        },
        create: {
          title: 'Event'
        }
      }
    },
    cover: {
      // set: [],
      connectOrCreate: {
        where: {
          url: answers.cover.cdnUrl,
        },
        create: {
          url: answers.cover.cdnUrl,
          metadata: answers.cover,
          owner: {
            connectOrCreate: {
              where: {
                email: '{{user_email}}',
              },
              create: {
                name: '{{user_name}}',
                email: '{{user_email}}',
              }
            }
          },
        }
      }
    },
    date: new Date(answers.date),
    tickets: answers?.tickets
  }
  try {
    const updatedEvent = await eventApi.update(answers?.id, {data: mappedAnswers});
    if(updatedEvent.status !== 200){
      throw new Error('Failed to update event');
    }
    actions.success();
    actions.goToStep('success');
  } catch (err) {
    eventUpdateFailed= true;
  }
  if (eventUpdateFailed) {
    actions?.error('Failed');
  }
}

export default {
  create,
  update
}