const fields = {
    cover: {
        label: 'Cover',
        component: ({ value: { url } }) => <>
            <div class="max-w-xs m-auto p-2">
                <img class="w-full object-cover " src={url} alt="Image" />
            </div>
        </>
    },
    title: {
        label: 'Title',
        component: ({ value }) => <span>{value}</span>,
    },
}

export default fields;