import {useRef, useCallback} from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import Test from './EditorBlock'

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  upload: Test,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  paragraph: Paragraph,
}

const ReactEditorJS = createReactEditorJS()

function Editor({
    label,
    content,
    onChange,
    readOnly,
    manualSave,
}) {
    const editorCore = useRef(null)
    const handleInitialize =useCallback((instance) => {
      editorCore.current = instance
    }, [])

    const handleSave = async () => {
        const { blocks } = await editorCore.current.save();
        onChange && onChange(blocks)
      }

  

    return <div className="flex flex-col w-full" >
        <label className="label">
            <span className="label-text">{label}</span>
        </label>
        <div className="bg-base-content text-black rounded-md pt-8 px-4" data-theme='el-drop' onBlur={handleSave}>
            <ReactEditorJS
                readOnly={readOnly}
                tools={EDITOR_JS_TOOLS} 
                onInitialize={handleInitialize}
                defaultValue={{ blocks: content, time: 1635603431943 }}/>
                
        </div>
        {!readOnly && manualSave && <button className="btn btn-block btn-secondary mt-12"  onClick={handleSave}>Continuar</button>}
    </div>
}
export default Editor;
