import { useState } from "react";
import { Widget } from "@uploadcare/react-widget";
import * as ReactDOM from 'react-dom';

const File = ({
    publicKey = '9d680dab696b71d0f587',
    id = 'file',
    previewStep = true,
    tabs = 'assets file url instagram',
    imageURL,
    label,
    sourceAssets,
    onFileChanged = file => console.log('File changed: ', file),
    onFileUploaded = info => console.log('File uploaded: ', info), // This will get called for every file that gets uploaded
    onUploadComplete = info => console.log('Upload complete: ', info), // while this will only get called when all files are uploaded
    onProgress = info => console.log('Progress: ', info.progress),
}) => {
    const [img, setImg] = useState(imageURL);
    const uploader = <Widget customTabs={{
        assets: (container, button, dialogApi, settings, name, uploadcare) => {
            if (!sourceAssets) {
                container.append('Cannot load assets')
            }
            container.css('padding', '32px');
            container.css('overflow-y', 'scroll');
            container.css('justify-content', 'flex-start');
            button[0].title = 'Assets';

            button.html(`<svg class="uploadcare--icon uploadcare--menu__icon" fill="#000000" width="32px" height="32px">
                <path d="M0 26.016q0 2.496 1.76 4.224t4.256 1.76h20q2.464 0 4.224-1.76t1.76-4.224v-20q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v20zM4 26.016v-20q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v20q0 0.832-0.576 1.408t-1.408 0.576h-20q-0.832 0-1.44-0.576t-0.576-1.408zM6.016 24q0 0.832 0.576 1.44t1.408 0.576h16q0.832 0 1.408-0.576t0.608-1.44v-0.928q-0.224-0.448-1.12-2.688t-1.6-3.584-1.28-2.112q-0.544-0.576-1.12-0.608t-1.152 0.384-1.152 1.12-1.184 1.568-1.152 1.696-1.152 1.6-1.088 1.184-1.088 0.448q-0.576 0-1.664-1.44-0.16-0.192-0.48-0.608-1.12-1.504-1.6-1.824-0.768-0.512-1.184 0.352-0.224 0.512-0.928 2.24t-1.056 2.56v0.64zM6.016 9.024q0 1.248 0.864 2.112t2.112 0.864 2.144-0.864 0.864-2.112-0.864-2.144-2.144-0.864-2.112 0.864-0.864 2.144z"></path>
            </svg>`);


            if (sourceAssets) {
                sourceAssets().then((assets) => {


                    const handleAssetClick = (asset) => {
                        onFileUploaded(asset.metadata);
                        setImg(asset.url);
                        uploadcare.closeDialog()
                    }

                    ReactDOM.render(
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gridGap: '10px' }}>
                            {assets.map((asset) => {
                                return <div id={asset.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', overflow: 'hidden' }} onClick={() => handleAssetClick(asset)}>
                                    <img style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 'inherit' }} src={asset.url} />
                                </div>
                            })}
                        </div>, container[0]

                    )

                });
            }



        }

    }} publicKey={publicKey} id={id} previewStep={previewStep} tabs={tabs}
        value={img}
        onFileSelect={(file) => {
            onFileChanged(file);
            if (file) {
                file.progress(info => onProgress(info))
                file.done(info => {
                    onFileUploaded(info);
                    setImg(info.cdnUrl);
                })
            }
        }}
        onChange={info => onUploadComplete(info)}
    />;
    return (
        <div className="w-full flex-col justify-center items-center text-center">
            <label className="label">
                <span className="label-text">{label}</span>
            </label>
            {img && (<div className="flex flex-col max-w-xs m-auto justify-center my-6">
                <img src={img} />
                {uploader}
            </div>)}
            {!img && <div className="flex flex-col max-w-xs h-48 m-auto justify-center border border-dashed border-white/20 rounded-lg my-6">
                {uploader}
            </div>}
        </div>

    )
};

export default File;