import { useState, useEffect } from 'react';
import Form from '@las-drop-tools/el-form';
import { api as makeApi } from '../../el-api';
import { updateArticle } from './forms';
import { useParams } from 'react-router-dom';

const Journalism = () => {

    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const artistsApi = makeApi('artist');
    const eventsApi = makeApi('event');
    const articlesApi = makeApi('article');
    const segmentsApi = makeApi('segment');
    const venuesApi = makeApi('place');
    const songsApi = makeApi('song');

    const { id } = useParams();

    useEffect(() => {
        const getArticle = async () => {
            const { data: article } = await articlesApi.findOne(id, { include: { events: true, artists: true, segment: true, cover: true, places: true, songs: true, media: true } });
            setLoading(false);
            if (article) {
                article.events = article?.events.map((event) => ({ label: event.title, value: event.id }));
                article.artists = article?.artists.map((artist) => ({ label: artist?.handle, value: artist?.id }));
                article.segment = { label: article?.segment?.label, value: article?.segment?.id };
                article.places = article?.places?.map((place) => ({ label: place.label, value: place.id }));
                article.songs = article?.songs?.map((song) => ({ label: song.title, value: song.id }));
                article.cover = article?.cover?.metadata;
                article.media = article?.media?.metadata;
                setFormData(article);
            }
        }
        getArticle();
    }, []);


    return formData ? <>
        <div className='py-8 px-8 lg:px-72 w-full'>
            <Form form={updateArticle} initialData={formData || {}} onChange={(x) => { const z = { ...formData, ...x?.answers }; console.log('newdata', z); setFormData(z) }} answers={formData} actions={{
                updateArticle: async (answers) => {

                    // const client = new UploadClient({ publicKey: 'YOUR_PUBLIC_KEY' });
                    articlesApi.update(id, {
                        data: {
                            generatedTTS: answers?.generatedTTS,
                        }
                    });
                    articlesApi.update(id, {
                        data: {
                            events: {
                                set: [],
                                connectOrCreate: answers?.events?.map((event) => ({
                                    where: {
                                        id: event?.value
                                    },
                                    create: {
                                        title: event?.label
                                    }
                                }))
                            },
                            artists: {
                                set: [],
                                connectOrCreate: answers.artists.map((artist) => ({
                                    where: {
                                        id: artist?.value,
                                    },
                                    create: {
                                        handle: artist?.label,
                                    }
                                })),
                            },
                            places: {
                                set: [],
                                connectOrCreate: answers?.places.map((place) => ({
                                    where: {
                                        id: place?.value,
                                    },
                                    create: {
                                        label: place?.label,
                                    }
                                })),
                            },
                            title: answers?.title,
                            copy: answers?.copy,
                            content: answers?.content,
                            generatedCopy: answers?.generatedCopy,
                            // generatedTTS: answers?.generatedTTS,
                            segment: {
                                connect: {
                                    label: answers?.segment?.label,
                                }
                            },
                            cover: {
                                connectOrCreate: {
                                    where: {
                                        url: answers?.cover?.cdnUrl,
                                    },
                                    create: {
                                        url: answers?.cover?.cdnUrl,
                                        metadata: answers?.cover,
                                        owner: {
                                            connectOrCreate: {
                                                where: {
                                                    email: '{{user_email}}',
                                                },
                                                create: {
                                                    name: '{{user_name}}',
                                                    email: '{{user_email}}',
                                                }
                                            }
                                        },
                                    }
                                }
                            },
                            //   media: {
                            //     connectOrCreate: {
                            //       where: {
                            //         url: answers?.media?.cdnUrl,
                            //       },
                            //       create: {
                            //         url: answers?.media?.cdnUrl,
                            //         metadata: answers?.media,
                            //         owner: {
                            //           connectOrCreate: {
                            //             where: {
                            //               email: '{{user_email}}',
                            //             },
                            //             create: {
                            //               name: '{{user_name}}',
                            //               email: '{{user_email}}',
                            //             }
                            //           }
                            //         },
                            //       }
                            //     }
                            //   },
                        }
                    }, {
                        include: {
                            events: true,
                            artists: true,
                            segment: true,
                            cover: true,
                            places: true,
                            songs: true
                        }
                    })
                },
                getArtists: () => artistsApi.findAll({}),
                getEvents: () => eventsApi.findAll({}),
                getVenues: () => venuesApi.findAll({}),
                getSongs: () => songsApi.findAll({}),
                createSong: (song) => songsApi.create({ data: song }),
                getSegments: () => segmentsApi.findAll({}),
                createEvent: (event) => eventsApi.create({ data: event }),
                upsertVenue: (venue) => venuesApi.upsert({ where: venue, update: venue, create: venue }),
                upsertArtist: (artist) => artistsApi.upsert({
                    where: artist,
                    update: artist,
                    create: artist
                })
            }}
            />
        </div>
    </> : loading ? <div>Loading...</div> : <div>Article not found</div>
}

export default Journalism;