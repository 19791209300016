import clsx from 'clsx';

function Text({
 copy,
 textType,
 size,
 alignment,
}) {
    const className = clsx(
        size === 'xs' && "text-xs",
        size === 'sm' && "text-sm",
        size === 'md' && "text-md",
        size === 'lg' && "text-lg",
        size === 'xl' && "text-xl",
        size === '2xl' && "text-2xl",
        size === '3xl' && "text-3xl",
        alignment === 'center' && "text-center"
    );
    switch (textType){
        case 'h1':
            return <h1 className={className}>{copy}</h1>
        default: 
            return <p className={className}>{copy}</p>
    }
}
export default Text;
