import { uploadCover, generalInfo, content, success, tickets } from './forms';

export const createEvent = {
    steps: [
      {
        id: 'upload-cover',
        form: uploadCover,
        actions: [
          {
            label: 'Siguiente',
            action: (actions, answers, errors) => {
              actions.goToStep('general-info');
            }
          }
        ]
      },
      {
        id: 'general-info',
        form: generalInfo,
        actions: [
          {
            label: 'Siguiente',
            action: (actions, answers, errors) => {
              actions.goToStep('tickets');
            }
          }
        ]
      },
      {
        id: 'tickets',
        form: tickets,
        actions: [
          {
            label: 'Siguiente',
            action: (actions, answers, errors) => {
              actions.goToStep('content');
            }
          }
        ]
      },
      {
        id: 'content',
        form: content,
        actions: [
        ]
      },
      {
        id: 'success',
        form: success,
        actions: []
      },
      
    ]
}

export default {
  createEvent
}