import { useState, useEffect } from 'react';
import { useHistory  } from 'react-router-dom';
import { api as makeApi } from '../../el-api';
import { Table } from '../../los-componentes';
import { toast } from 'react-toastify';

export const Card = ({ event, onBuy, isLoading, isFeatured, isMinimal = false }) => {
    // const history = useHistory();
    const className = isFeatured ? 'card glass w-full' : 'card glass w-72 sm:w-96';
    return (
        // <div className="card w-full h-5/6 bg-base-100 shadow-xl image-full">
        //     <figure><img src={event.cover?.url} alt="Shoes" /></figure>
        //     <div className="card-body">
        //         <h2 className="card-title">{event?.title}</h2>
        //         {JSON.stringify(event?.content)}
        //         <div className="card-actions justify-end">
        //             <button className="btn btn-primary" onClick={onBuy}>Buy Now</button>
        //         </div>
        //     </div>
        // </div>
        <div className={className}>
            {/* <figure className='max-h-64'><img src={event.cover?.url} alt="car!" /></figure> */}
            <figure className={isMinimal ? 'h-auto' : 'max-h-64'}><img src={event.cover?.url} alt="car!" /></figure>
            <div className="card-body items-center">
                {!isMinimal && <h2 className="card-title">{event.title}</h2>}
                {!isMinimal && event.description.split('<br/>').map((p)  => <p className='text-center my-2'>{p}</p>
                )}
                <div className="card-actions justify-center mt-4">
                   {!!event.referralURL && !isMinimal ? <button className='btn btn-primary' onClick={()=> { window.location.replace(event.referralURL)}}>¡Obtener entrada gratis!</button> :
                   onBuy ? <button className="btn btn-primary" onClick={isLoading ? ()=>{} : onBuy} disabled={isLoading}>{!isLoading ? '¡Obtener entrada!' : <span className="loading loading-ring loading-md"></span>}</button> : <button disabled className='btn btn-secondary'>Invitation Only</button>}
                </div>
            </div>
        </div>
    )
}

const ReadEvents = () => {
    const eventsApi = makeApi('event');
    const [events, setEvents] = useState();

    const history = useHistory();

    useEffect(() => {
        eventsApi.findAll({
            include: {
                cover: true,
                // artists: true,
                location: true,
                owner: true,
            },
            orderBy: {
                createdAt: 'desc'
            }
        }).then(({ data }) => {
            setEvents(data)
        });
    }, []);

    const [featuredEvent, ...rest] = events || [];


    return events ? (
        <div className='py-8 px-8 lg:px-24 w-full h-4/6'>
            <Card event={featuredEvent} isFeatured={true}/>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-16 my-20 gap-y-20'>
                {[...rest].map((event) => (
                    <Card event={event} />
                ))}
            </div>
        </div>
    ) : 'loading...';

}

export default ReadEvents;