import { useArtist } from "./Create";

export const create = async ({ actions }) => {
    let createFailed;
    const { artist: { answers } } = useArtist.getState();
    const mappedAnswers = {
       
            "name": answers?.name,
            "artistName": answers?.artistName,
            "genres": [ "some-default"],
            "bio": answers?.bio,
            "socialMedia": answers?.socialMedia,
            "avatar": {
                connectOrCreate: {
                  where: {
                    url: answers.avatar.cdnUrl,
                  },
                  create: {
                    url: answers.avatar.cdnUrl,
                    metadata: answers.avatar,
                    owner: {
                      connectOrCreate: {
                        where: {
                          email: 'erickaleruano@gmail.com',
                        },
                        create:{
                          name: 'Erick',
                          email: 'erickaleruano@gmail.com',
                        }
                      }
                    },
                  }
                }
              },
            "bookingAgents": {
                "create": answers?.bookingAgents
            }

    }
    try {
        await actions.create(mappedAnswers);
        actions.goToStep('success');
    } catch (err) {
        createFailed = true;
    }
    if (createFailed) {
        actions.alert('Failed');
    }
}