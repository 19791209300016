// import { useCreateEvent } from "./Create";
import { create } from './actions';

export const success = {
  id: 'success',
  fields: [
    {
      type: 'confetti',
      key: 'confetti',
      active: (answers, errors) => true,
    },
    {
      type: 'hero',
      title: 'Tu evento se ha creado exitosamente',
      content: `
        Recuerda que puedes actualizar la info de tu evento en cualquier momento.
      `,
      button: {
        copy: 'Ir a mi nuevo evento',
        onClick: ({ actions }) => actions.goTo('/events')
      },
      image: 'success',
      key: 'thanksHero',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
  ]
}

export const addArtist = {
  id: 'addArtist',
  fields: [
    {
      type: 'input',
      placeholder: 'Nombre y apellido (opcional)',
      label: 'Nombre',
      key: 'name'
    },
    {
      type: 'input',
      placeholder: 'Madjuaneke',
      label: 'Nombre Artistico',
      key: 'artistName'
    },
    {
      type: 'file',
      key: 'avatar',
    },
    {
      type: 'text',
      copy: 'Selecciona o sube una foto de portada para tu evento',
      alignment: 'center',
      size: '2xl'
    },
    {
      type: 'editor',
      key: 'bio',
      customAction: create,
    }
  ]
};
