import { useState, useEffect } from 'react';
import Form from '@las-drop-tools/el-form';
import { api as makeApi } from '../../el-api';
import { createSong } from './forms';

export default () => {

    const [formData, setFormData] = useState({});
    const songsApi = makeApi('song');
    const artistsApi = makeApi('artist');


    return <>
        <div className='py-8 px-8 lg:px-72 w-full'>
            <Form form={createSong} initialData={formData || {}} onChange={(x) => { const z = { ...formData, ...x?.answers }; console.log(z); setFormData(z) }} answers={formData} actions={{
                createSong: (answers) => {
                    songsApi.create({
                        data: {
                            artists: {
                                connectOrCreate: answers.artists.map((artist) => ({
                                    where: {
                                        id: artist?.value,
                                    },
                                    create: {
                                        handle: artist?.label,
                                    }
                                })),
                            },
                            title: answers?.title,
                            cover: {
                                connectOrCreate: {
                                    where: {
                                        url: answers?.cover?.cdnUrl,
                                    },
                                    create: {
                                        url: answers?.cover?.cdnUrl,
                                        metadata: answers?.cover,
                                        owner: {
                                            connectOrCreate: {
                                                where: {
                                                    email: '{{user_email}}',
                                                },
                                                create: {
                                                    name: '{{user_name}}',
                                                    email: '{{user_email}}',
                                                }
                                            }
                                        },
                                    }
                                }
                            },
                        }
                    })
                },
                getArtists: () => artistsApi.findAll({}),
                upsertArtist: (artist) => artistsApi.upsert({
                    where: artist,
                    update: artist,
                    create: artist
                })
            }}
            />
        </div>
    </>
}
