import React, { useEffect, useRef } from 'react';

const renderTemplate = (template, header, subheader, imageUrl, elementId) => {
  const canvas = document.createElement('canvas');
  canvas.className = 'max-w-xs m-auto p-2';
  canvas.width = 1000;
  canvas.height = 1000;
  const ctx = canvas.getContext('2d');

  // Load background image
  const backgroundImg = new Image();
  backgroundImg.src = template.backgroundImageUrl;
  backgroundImg.onload = () => {
    ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);

    // Load and draw main content image
    const contentImg = new Image();
    contentImg.src = imageUrl;
    contentImg.onload = () => {
      const contentSize = canvas.width * 0.6;
      const contentX = (canvas.width - contentSize) / 2;
      const contentY = (canvas.height - contentSize) / 2;

      // Draw content image
      ctx.drawImage(contentImg, contentX, contentY, contentSize, contentSize);

      // Add header and subheader text
      ctx.font = 'bold 40px Arial';
      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      ctx.fillText(header, canvas.width / 2, contentY + contentSize + 50);
      ctx.font = '24px Arial';
      ctx.fillText(subheader, canvas.width / 2, contentY + contentSize + 90);

      // Load foreground image
      const foregroundImg = new Image();
      foregroundImg.src = template.foregroundImageUrl;
      foregroundImg.onload = () => {
        // Calculate foreground image dimensions
        const foregroundWidth = canvas.width;
        const foregroundHeight = (foregroundWidth * foregroundImg.height) / foregroundImg.width;

        // Calculate foreground image position
        const foregroundX = 0;
        const foregroundY = (canvas.height - foregroundHeight) / 2;

        // Draw foreground image
        ctx.drawImage(foregroundImg, foregroundX, foregroundY, foregroundWidth, foregroundHeight);

        // Render canvas on the screen
        const resultContainer = document.getElementById(elementId);
        resultContainer.innerHTML = '';
        resultContainer.appendChild(canvas);
      };
    };
  };
};

const TemplateRenderer = ({ template, header, subheader, imageUrl }) => {
  const canvasRef = useRef(null);

  const elementId = `result-container-${header.replace(' ', '_').toLowerCase()}`;

  useEffect(() => {
    renderTemplate(template, header, subheader, imageUrl, elementId);
  }, [template, header, subheader, imageUrl]);

  return <div id={elementId} className="max-w-xs" ref={canvasRef}></div>;
};

export default TemplateRenderer;
