import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { api as makeApi } from '../../el-api';
import ticketSeams from './seams.png';
import QRCode from 'qrcode';

const UserTickets = () => {
    const ticketsApi = makeApi('ticket');
    const [tickets, setTickets] = useState();
    const user = useUser();

    useEffect(() => {
        ticketsApi.findAll({
            where: {
                owner: {
                    email: '{{user_email}}',
                }
            },
            include: {
                event: {
                    include: {
                        cover: true,
                    }
                },
                owner: true,
            },
        }).then(({ data }) => {
            setTickets(data);
        });
    }, []);

    const PrintQR = ({
        ticket,
    })=>{
        const [generatedQR, setGeneratedQR] = useState();
        useEffect(async () => {
            const qr = await QRCode.toDataURL(JSON.stringify({
                id: ticket.id,
                owner: user.user.primaryEmailAddress.emailAddress,
                event: ticket.event.id,
            }), {
                scale: 10
            });
            setGeneratedQR(qr);
        });
        
        return generatedQR ? <img className='w-full' src={generatedQR} /> : <>'loading...'</>;
    }

    return tickets ? (
        <div className='py-8 px-8 lg:px-24 w-full h-auto'>
            <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-x-16 gap-y-16'>
                {tickets.map((ticket) => (
                    <div className='rounded-lg shadow-lg overflow-hidden my-8'>
                        <div className='flex flex-col items-center justify-center'>
                            <img className='w-full' src={ticket.event.cover?.url} alt="car!" />
                            <img src={ticketSeams} alt="ticket seams" className='w-full' />
                            <PrintQR ticket={ticket} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : 'loading...';

}

export default UserTickets;