import { useState, useEffect } from 'react';
import {
    useUser,
} from "@clerk/clerk-react";
import Form from '@las-drop-tools/el-form';

export default function ElFlow({
    flow = { steps: [] },
    step = null,
    initialData: flowInitialData,
    flowData = { answers: [], errors: []},
    onStepChange = (step) => console.log(step),
    onUpdate,
    actions,
}) {
    const [currentStep, setCurrentStep] = useState(step || flow?.steps?.[0] || { id: 'notFound' });
    const { user } = useUser();

    useEffect(() => {
        setCurrentStep(step);
    }, [step])

    const handleUpdate = (form) => {
        onUpdate && onUpdate(form)
    }
    const initialData = {
        ...flowInitialData,
        userName: user.fullName
    };

    const allActions = {
        goToStep: (stepId) => {
            const step = flow.steps.find((step) => step.id === stepId)
            setCurrentStep(step);
            onStepChange && onStepChange({ step, ...flowData });
        },
        ...actions
    };

    return (
        <>
            <div className="md:mx-24 xl:mx-96 min-h-screen h-full flex flex-col justify-center">
                {flow.steps.map((step) => step.id === currentStep?.id && (
                    <Form key={step.id} form={step?.form} onChange={handleUpdate} initialData={initialData} answers={flowData.answers} errors={flowData.errors} actions={allActions}></Form>
                )
                )}
                {!!currentStep?.actions?.length && <div key={currentStep?.id + '-'} className="flex flex-col w-full space-y-4 justify-between my-8">
                    {
                        currentStep.actions.map((action, i) =>
                            <button key={`${step.id}-${i}`} className="btn btn-block btn-secondary" onClick={() => { action.action(allActions, flowData.answers, flowData.errors) }}>
                                {typeof action.label === 'string' ? action.label : action.label(flowData.answers, flowData.errors)}
                            </button>)
                    }
                </div>}
                {/* TO DO: Agregar controles */}
            </div>
        </>
    )
}   