import { useState } from 'react';

function Item({
  imageURL,
  title,
  price,
  description
}) {
  return (
    <div className="flex flex-row xl:border-b-2 xl:border-b-white/10 w-full">
      <figure className="rounded-full w-16 h-16 m-4 self-center"><img className="object-cover w-full h-full rounded-full" src={imageURL} alt={title} /></figure>
      <div className="card-body flex-row justify-between pl-0">
        <div className='flex flex-col grow content-center justify-center'>
          <h2 className="card-title">{title}</h2>
          <p>{description}</p> 
        </div>
        <div className="card-actions justify-self-end justify-center content-center">
          <p className='max-h-6 font-bold text-xl'>{price}</p>
        </div>
      </div>
    </div>
  );
}

export default Item;
