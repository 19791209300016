import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCreateEvent = create(persist(
  (set, get) => ({
    flowData: { answers: [], errors: [] },
    resetFlowData: () => set(() => ({
      flowData: {
        answers: [],
        errors: [],
      }
    })),
    setFlowData: (flowData) => set((state) => ({
      ...state,
      flowData: {
        answers: {
          ...state.flowData.answers,
          ...flowData.answers
        },
        errors: {
          ...state.flowData.errors,
          ...flowData.errors
        }
      }
    })),
  }),
  {
    name: 'new-event'
  }));

export default useCreateEvent;