import React, { useState, useEffect } from 'react';
import DomConfetti from 'react-dom-confetti';

const defaultConfig = {
  angle: "111",
  spread: 360,
  startVelocity: 40,
  elementCount: "161",
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "12px",
  height: "12px",
  perspective: "547px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

export default function Confetti({
  active,
  config,
}){
  const [throwConfetti, setThrowConfetti] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(()=>{
    active && setTimeout(()=> setThrowConfetti(true), 100);
  }, [active]);

  return <div className='fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
        <DomConfetti width={width} height={height} active={ throwConfetti } config={ { ...defaultConfig, ...config } }/>
    </div>
}