import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api as makeApi } from '../../el-api';
import { Table } from '../../los-componentes';
import Invitations from './Invitations';
import tableComposition from './tableComposition';

const EventsTable = () => {
    const eventsApi = makeApi('event');

    const [selectedEvent, setSelectedEvent] = useState();
    const [events, setEvents] = useState();

    const history = useHistory();

    useEffect(async () => {
        eventsApi.findAll({
            include: {
                cover: true,
                location: true,
                owner: true,
                invitations: true,
            },
        }).then(async ({ data: events }) => {
            setEvents(events)
        });
    }, []);

    const handleDelete = (id) => {
        eventsApi.destroy(id).then(({ status, error }) => {
            if (status !== 200) {
                toast.error(error);
            } else {
                setEvents(events.filter((event) => event.id !== id));
                toast.success('Event deleted successfully');
            }
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    const handleEdit = (id) => {
        history.push(`/administrar/eventos/${id}/actualizar/upload-cover`);
    };

    const showInvitesModal = (event) => {
        setSelectedEvent(event);
        window.eventInvitesModal.showModal();
    };

    return (
        <div className='py-8 px-8 lg:px-24 w-full'>
            <h1 className='text-7xl font-bold uppercase py-8'>Gestiona tus eventos</h1>
            <Invitations events={events} selectedEvent={selectedEvent} setEvents={setEvents} />
            <div className="p-4 flex flex-row-reverse ">
                <button className="btn btn-outline" onClick={() => history.push('/administrar/eventos/crear/upload-cover')}>Crear Evento</button>
            </div>
            <Table
                fields={tableComposition}
                items={events}
                menuItems={[{ label: 'Editar', action: handleEdit, icon: 'edit' }, { label: 'Eliminar', action: handleDelete, icon: 'delete' }, { label: 'Crear Invitaciones', action: showInvitesModal, icon: 'send' }]}
                actions={{
                    handleDelete,
                    handleEdit,
                }} />
        </div>
    );

}

export default EventsTable;