import assets from "./assets";

export default function Hero({
    content = 'Content',
    title = 'Title',
    image = 'map',
    button,
    context,
}){
    return (
        <div className="flex flex-col justify-center items-center p-2 h-full justify-self-center">
            <img src={assets[image]} alt={image} className="w-72" />
            <h1 className="text-4xl justify-self-center self-center ml-4 mt-8 mb-4">{title}</h1>
            <p className="text-2xl justify-self-center self-center text-center ml-4">{content}</p>
            {button && (
                <button className="btn btn-primary mt-8" onClick={()=>button?.onClick(context)}>{button?.copy}</button>
            )}
        </div>
    )
}