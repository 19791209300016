import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

export default ({
  value: defaultValue,
  className,
  name = 'select',
  label,
  sourceOptions,
  onChange,
  onCreate,
  actions,
  answers,
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(defaultValue || []);

  useEffect(async () => {
    if (!options.length) {
      const sourcedOptions = await sourceOptions(actions) || [];
      setOptions(sourcedOptions);
    }
  }, [])

  const handleCreate = async (inputValue) => {
    setIsLoading(true);
    setValue((prev) => [...prev, { label: "creating...", value: null }])
    const created = await onCreate(actions, inputValue, answers);
    setIsLoading(false);
    setOptions((prev) => {
      return prev && Array.isArray(prev) ? [...prev, created] : []
    });
    setValue((prev) => [...prev, created]);
    onChange([...value, created]);
  };


  return <div className='text-left w-full'>
    <label className="label text-left">
      <span className="label-text text-left">{label}</span>
    </label>
    <CreatableSelect
      isMulti
      name={name}
      options={options}
      className={`basic-multi-select w-full text-black ${className}`}
      classNamePrefix="select"
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => { console.log(newValue, options); setValue(newValue); onChange(newValue) }}
      onCreateOption={handleCreate}
      defaultValue={value}
    />
  </div>
}
  ;