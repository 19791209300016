import React, { useState, useEffect } from 'react';
import { api as makeApi } from '../el-api';
import { QrReader } from 'react-qr-reader';

export const ScanTicket = (props) => {
  const [data, setData] = useState('No result');
  return (
    <>
      <QrReader
        constraints={{
          facingMode: 'environment'
        }}
        scanDelay={500}
        onResult={async (result, error) => {
          if (!!result) {
            setData(result?.text);
            const { id } = JSON.parse(result?.text);
            if(!id) return alert('Ticket inválido');
            makeApi('ticket').findOne(id).then(({ data }) => {
              if (!data) {
                alert('Ticket no encontrado');
              } else {
                alert('Ticket encontrado');
              }
            }
            );
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
    </>
  );
};

export default ScanTicket