import Create from './Create';
import Update from './Update';
import Events from './Table';
import EventsGrid from './Grid';
import EventInvitation from './Invitation';
import Scan from './Scan';
import Header from '../../el-header';
import UserTickets from './UserTickets';

export default [
    {
        path: '/administrar/eventos/validar-ticket',
        isPrivate: true,
        ui: <>
            <Header />
            <Scan />
        </>
    },
    {
        path: '/administrar/eventos/crear/:stepId',
        isPrivate: true,
        ui: <>
            <Header />
            <Create />
        </>
    },
    {
        path: '/administrar/eventos/crear',
        isPrivate: true,
        type: 'redirect',
        redirectTo: '/administrar/eventos/crear/upload-cover'
    },
    {
        path: '/administrar/eventos/:id/actualizar/:stepId',
        isPrivate: true,
        ui: <>
            <Header />
            <Update />
        </>
    },
    {
        path: '/administrar/eventos',
        isPrivate: true,
        ui: <>
            <Header />
            <Events />
            {/* <EventsGrid /> */}
        </>
    },
    {
        path: '/eventos/invitacion/:id',
        isPublic: true,
        isPrivate: true,
        ui: <>
            <EventInvitation />
        </>
    },
    {
        path: '/eventos/compartir/:id',
        isPrivate: true,
        ui: <>
            <EventInvitation />
        </>
    },

    {
        path: '/eventos',
        isPrivate: true,
        ui: <>
            <Header />
            <EventsGrid />
        </>
    },
    {
        path: '/tickets',
        isPrivate: true,
        ui: <>
            <Header />
            <UserTickets />
        </>
    }
]