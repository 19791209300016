export const items = [
  {
    imageURL: 'https://images.pexels.com/photos/1279107/pexels-photo-1279107.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    title: 'Item 1',
    price: '$2250',
    description: 'This is the description of the product'
  },
  {
    imageURL: 'https://images.pexels.com/photos/3612182/pexels-photo-3612182.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    title: 'Item 2',
    price: '$2250',
    description: 'This is the description of the product'
  },
  {
    imageURL: 'https://images.pexels.com/photos/2533266/pexels-photo-2533266.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    title: 'Item 3',
    price: '$2250',
    description: 'This is the description of the product'
  },
  
];

const validations = {
  passwordsMustMatch: ({ passwordKey = 'password' } = { passwordKey: 'password' }) => ({
    condition: ({ answers, value }) =>
      (passwordKey in answers && 'confirmPassword' in answers)
        ? value === answers?.[passwordKey]
        : true,
    message: 'Passwords dont match'
  })
}

export const orderSummary = {
  id: 'order-summary',
  fields: [
    {
      type: 'icon-header',
      copy: 'Tus Compras',
      icon: 'checkout',
      key: 'cartTitle',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'item-list',
      key: 'cartItemList',
      items,
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      },
      
    },
  ]
}


export const billingAddress = {
  id: 'billing-address',
  fields: [
    {
      type: 'icon-header',
      copy: 'Dirección de facturación',
      icon: 'billing',
      key: 'billingAddressHeader',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'input',
      label: 'Dirección',
      key: 'billingAddress',
      inputType: 'text',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'checkbox',
      label: 'Usar misma dirección para shipping',
      key: 'sameAddress',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
  ]
}


export const shippingAddress = {
  id: 'shipping-address',
  fields: [
    {
      type: 'icon-header',
      copy: 'El Shipping',
      icon: 'shipping',
      key: 'shippingHeader',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'input',
      label: 'Dirección',
      key: 'shippingAddress',
      inputType: 'text',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
  ]
}

export const cardPayment = {
  id: 'card-payment',
  fields: [
    {
      type: 'credit-card',
      key: 'creditCard',
      // TODO: We should not be storing functions in db so this should be serializable in the future.
      card: (answers) => {
        return {
          name: answers?.userName || 'Name Card',
          number: answers?.cardNumber || '1234 5678 9101 1121',
          expMonth: answers?.cardExpMonth || '10',
          expYear: answers?.cardExpYear || '2069',
        }
      },
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'text',
      copy: 'Ingresa los datos de tu tarjeta para crear un método de pago',
      alignment: 'center',
      size: '2xl'
    },
    {
      type: 'input',
      label: 'Card Number',
      key: 'cardNumber',
      inputType: 'number',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
    {
      type: 'row',
      key: 'group',
      fields: [
        {
          type: 'month',
          label: 'Expiry Month',
          key: 'cardExpMonth',
          selected: '01',
          props: {},
          validation: {
            condition: () => true,
            message: 'Is required.'
          }
        },
        {
          type: 'year',
          label: 'Expiry Year',
          key: 'cardExpYear',
          props: {},
          validation: {
            condition: () => true,
            message: 'Is required.'
          }
        },
        {
          type: 'input',
          label: 'CCV',
          key: 'cardCCV',
          inputType: 'password',
          props: {},
          validation: {
            condition: () => true,
            message: 'Is required.'
          }
        },
      ]
    }
  ]
}

export const thanksPage = {
  id: 'thanks',
  fields: [
    {
      type: 'confetti',
      key: 'confetti',
      active: (answers, errors) => true,
    },
    {
      type: 'hero',
      title: '¡Felicidades!',
      content: `
        Tu compra se ha procesado correctamente.  Hemos enviado los detalles a tu correo electrónico
      `,
      button: {
        copy: 'Seguir descubriendo eventos',
        onClick: ({actions}) => actions.goTo('/')
      },
      image: 'consumer',
      key: 'thanksHero',
      props: {},
      validation: {
        condition: () => true,
        message: 'Is required.'
      }
    },
  ]
}

export const flow = {
  steps: [
    {
      id: 'summary',
      form: orderSummary,
      actions: [
        {
          label: 'billing',
          action: (actions, answers, errors) => {
            if (answers?.useBilling) {
              actions.goToStep('billing');
              return;
            }
            if (answers?.useShipping) {
              actions.goToStep('shipping');
              return;
            }
            actions.goToStep('payment');
          }
        }
      ]
    },
    {
      id: 'billing',
      form: billingAddress,
      actions: [
        {
          label: (answers) => 'shipping',
          action: (actions, answers, errors) => {
            if (answers?.useShipping) {
              actions.goToStep('shipping');
              return;
            }
            actions.goToStep('payment');
          }
        },

      ]
    },
    {
      id: 'shipping',
      form: shippingAddress,
      actions: [
        {
          label: 'payment',
          action: (actions, answers, errors) => {
            actions.goToStep('payment');
          }
        },
      ]
    },
    {
      id: 'payment',
      form: cardPayment,
      actions: [
        {
          label: 'thanks',
          action: (actions, answers, errors) => { actions.goToStep('thanks'); }
        },
      ]
    },
    {
      id: 'thanks',
      form: thanksPage,
      actions: [
      ]
    }
  ]
}



export default {
  items,
}