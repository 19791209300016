function Input({
    label,
    onChange,
}) {
    return <>
  <div className="form-control w-full border-slate-400/20 border my-4 p-1">
    <label className="cursor-pointer label">
      <input type="checkbox" className="toggle toggle-primary"
      onChange={({ target: { checked }}) => onChange(checked)}
      />
      <span className="label-text w-full text-left ml-4 text-lg">{label}</span> 
    </label>
  </div>
    </>
}
export default Input;



