import { default as React } from 'react';
import ReactDOM from 'react-dom';
import File from './File'

// To do: Move this logic to the File component itself.
const Uploader = ({ data, onDataChange, readOnly }) => {
    return <>
        {readOnly ? <img src={data && data?.cdnUrl} /> : <File imageURL={data && data?.cdnUrl} onFileUploaded={(x) => onDataChange(x)} /> }
    </>
}

// To do: This should be a factory that returns a generic class wrapping any component
export default class Timeline {

    static get toolbox() {
        return {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M452 854h60V653l82 82 42-42-156-152-154 154 42 42 84-84v201ZM220 976q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z"/></svg>`,
            title: 'Upload',
        };
    }

    static get isReadOnlySupported() {
        return true;
    }

    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;
        this.data = {
            ...data
        };

        this.CSS = {
            wrapper: 'walkthrough-timeline',
        };

        this.nodes = {
            holder: null,
        };
    }

    render() {
        const rootNode = document.createElement('div');
        rootNode.setAttribute('class', this.CSS.wrapper);
        this.nodes.holder = rootNode;

        const onDataChange = (newData) => {
            this.data = {
                ...newData
            };
        }

        ReactDOM.render(
            (
                <Uploader
                    onDataChange={onDataChange}
                    readOnly={this.readOnly}
                    data={this.data} />
            ),
            rootNode);
        return this.nodes.holder;
    }

    save() {
        return this.data;
    }
}