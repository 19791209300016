import React, { useState, useCallback } from 'react';
import { useZxing } from "react-zxing";
import { Icon } from '../../los-componentes';
import { api as makeApi } from '../../el-api';
import { toast } from 'react-toastify';

function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}


const Scanner = ({ onChange }) => {
    const debouncedOnChange = useCallback(debounce(onChange, 500), [onChange]); // Debouncing with a 500ms delay

    const { ref } = useZxing({
        onResult(result) {
            debouncedOnChange(result.getText())
        },
    });

    return <video ref={ref} />
}


const HomePage = () => {
    const [result, setResult] = useState("");
    const [showUser, setShowUser] = useState(false);
    const [loading, setLoading] = useState(false);

    return <div className="flex flex-col gap-2 items-center justify-center w-full h-full">
        {showUser && <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center">
            <img src={showUser} className="rounded-full w-32 h-32" />
        </div>}
        <span className="pb-4 flex flex-col items-center">
            {!loading && <Scanner onChange={(result) => {
                const { id, owner } = JSON.parse(result);
                if (!id) return alert('Ticket inválido');
                const wasLoading = loading;
                setLoading(true);
                setResult(`${owner}`);
                !wasLoading && makeApi('ticket').findOne(id, {
                    include: {
                        owner: true,
                    }
                }).then(({ data }) => {
                    setLoading(false);
                    if (!data) {
                        setResult('Ticket inválido');
                        toast.error('Ticket inválido');
                    } else {
                        setShowUser(data.owner.authData.imageUrl);
                        setTimeout(() => setShowUser(false), 5000);
                        if (data.used) return toast.error('Este ticket ya había sido utilizado');
                        toast.success('Ticket válido - ' + `Pertenece a ${data.owner.name}, puede ingresar`, {
                            autoClose: 30000,
                        });
                        makeApi('ticket').update(id, {
                            data: {
                                used: true,
                            }
                        }).then(() => {
                            setResult('');
                            // setManually(false);
                            // toast.success('Ticket escaneado - ' + `Pertenece a ${data.owner.name}`, {
                            //   autoClose: 30000,
                            //});
                        });
                    }
                }
                );
            }
            } />}
        </span>
        <input type="text" placeholder="Código de ticket" className="input input-bordered input-primary w-full max-w-xs my-4 text-center" disabled={true} defaultValue={result} onChange={(x) => setResult(x.target.value)} />
    </div>
}

export default HomePage