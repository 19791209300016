import { api as makeApi } from '../../el-api';

// Move this into audio generator component
const fetchAndProcessAudioStream = (url) => {
  return new Promise(async (resolve, reject) => {
    try {

      const response = await fetch(url);
      const reader = response.body.getReader();
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
      }

      const audioData = new Blob(chunks);
      const readerBase32 = new FileReader();

      readerBase32.readAsDataURL(audioData);
      readerBase32.onloadend = function () {
        const base32Data = readerBase32.result.split(",")[1];
        // You can now store the base32 data in a variable or perform any further processing
        resolve(base32Data);
      };
    } catch (e) {
      reject(e);
    }
  });
}

// this should probably be an action
const assetsApi = makeApi('asset');


const songFields = [
  {
    type: 'file',
    label: 'Portada de la pista',
    key: 'cover',
    sourceAssets: async () => {
      const {data} = await assetsApi.findAll();
      return data;
    }
  },
  {
    type: 'input',
    placeholder: 'Song title',
    label: 'Título de la pista',
    key: 'title',
  },
  {
    type: 'input',
    placeholder: 'YT URL',
    label: 'URL YOUTUBE',
    key: 'youtubeURL',
  },
  {
    type: 'input',
    placeholder: 'Spotify URL',
    label: 'Spotify URL',
    key: 'spotifyURL',
  },
  {
    type: 'select-multi',
    label: 'Selecciona los artistas relacionados',
    key: 'artists',
    sourceOptions: async (actions) => {
      const { data } = await actions.getArtists()
      return data.map((artist) => ({ label: artist?.handle, value: artist?.id })) || []
    },
    onCreate: async (actions, artist) => {

      const { data } = await actions.upsertArtist({ handle: artist.startsWith('@') ? artist : `@${artist}` });
      return {
        label: data?.handle, value: data?.id
      };
    },
  },
  
  // {
  //   type: 'computed',
  //   key: 'generatedCopy',
  //   compute: async (answers) => {
  //     const prompt = `la canción nueva se llama ${answers?.title} por el/los artistas ${answers?.artists?.map((artist) => artist?.label).join(', ')} `
  //     return fetch('/api/services/chatgpt?prompt=' + prompt).then((res) => res.json())
  //   }
  // },
  // {
  //   type: 'computed',
  //   key: 'generatedTTS',
  //   condition: (answers) => {
  //     return !!answers?.generatedCopy?.[0];
  //   },
  //   compute: async (answers) => {
  //     console.log('ans', answers);
  //     const prompt = Array.isArray(answers.generatedCopy) ? answers?.generatedCopy?.[0] : answers?.generatedCopy;
  //     const audioStreamUrl = "/api/services/elevenlabs?prompt=" + encodeURIComponent(prompt.trim());
  //     const audio = await fetchAndProcessAudioStream(audioStreamUrl);
  //     return audio;
  //   },
  //   partial: (field, value) => () => {
  //     return <>
  //       <h1>audio</h1>
  //       <audio controls="controls" autobuffer="autobuffer" >
  //         <source src={`data:audio/wav;base64,${value}`} />
  //       </audio>
  //     </>
  //   }
  // },
];

export const createSong = {
  id: 'createSong',
  fields: [
    ...songFields,
    {
      type: 'button',
      copy: 'Crear',
      onClick: ({ answers, actions, error }) => {
        actions.createSong(answers)
      }
    }
  ]
};

export const updateSong = {
  id: 'updateSong',
  fields: [
    ...songFields,
    {
      type: 'button',
      copy: 'Actualizar',
      onClick: ({ answers, actions, error }) => {
        actions.updateSong(answers)
      }
    }
  ]
};