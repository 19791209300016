import Select from "./Select";

function Month({
    label,
    placeholder,
    selected,
    onChange,
}) {
    const options = [
        { label: 'Jan', value: '01' },
        { label: 'Feb', value: '02' },
        { label: 'Mar', value: '03' },
        { label: 'Apr', value: '04' },
    ]
    return <Select options={options} label={label} placeholder={placeholder} selected={selected} onChange={onChange} />
}
export default Month;
