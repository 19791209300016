import { useState, useEffect } from 'react';
import Form from '@las-drop-tools/el-form';
import { api as makeApi } from '../../el-api';
import { createArticle } from './forms';

const Journalism = () => {
    
    const [formData, setFormData] = useState({});
    const artistsApi = makeApi('artist');
    const eventsApi = makeApi('event');
    const articlesApi = makeApi('article');
    const segmentsApi = makeApi('segment');
    const venuesApi = makeApi('place');
    const songsApi = makeApi('song');


    return <>
        <div className='py-8 px-8 lg:px-72 w-full'>
            <Form form={createArticle}  initialData={formData || {}} onChange={(x)=> {const z = {...formData, ...x?.answers}; console.log(z); setFormData(z)}} answers={formData} actions={{
                createArticle: (answers)=>{
                     articlesApi.create({
                    data: {
                        events: {
                            connectOrCreate: answers?.events?.map((event)=>({
                                where: {
                                    id: event?.value
                                },
                                create: {
                                    title: event?.label
                                }
                            }))
                        },
                        artists: {
                            connectOrCreate: answers.artists.map((artist)=>({
                                where: {
                                    id: artist?.value,
                                },
                                create: {
                                    handle: artist?.label,
                                }
                            })),
                        },
                        places: {
                            connectOrCreate: answers.places.map((place)=>({
                                where: {
                                    id: place?.value,
                                },
                                create: {
                                    label: place?.label,
                                }
                            })),
                        },
                        title: answers?.title,
                        copy: answers?.copy,
                        content: answers?.content,
                        segment: {
                            connect: {
                                label: 'Los Imperdibles'
                            }
                        },
                        cover: {
                            connectOrCreate: {
                                where: {
                                  url: answers?.cover?.cdnUrl,
                                },
                                create: {
                                  url: answers?.cover?.cdnUrl,
                                  metadata: answers?.cover,
                                owner: {
                                  connectOrCreate: {
                                    where: {
                                      email: '{{user_email}}',
                                    },
                                    create: {
                                      name: '{{user_name}}',
                                      email: '{{user_email}}',
                                    }
                                  }
                                },
                              }
                            }
                          },
                    }
                }) },
                getArtists: ()=> artistsApi.findAll({}) ,
                getEvents: ()=> eventsApi.findAll({}) ,
                getVenues: ()=> venuesApi.findAll({}) ,
                getSongs: ()=> songsApi.findAll({}) ,
                createSong: (song)=> songsApi.create({ data: song}),
                getSegments: ()=> segmentsApi.findAll({}),
                createEvent: (event)=> eventsApi.create({ data: event}), 
                upsertVenue: (venue)=> venuesApi.upsert({ where: venue, update: venue, create: venue}),
                upsertArtist: (artist)=> artistsApi.upsert({
                    where: artist,
                    update: artist,
                    create:artist}) 
                }}
                 />
        </div>
    </>
}

export default Journalism;