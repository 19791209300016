import icons from "./icons";

export default function IconHeader({
    copy = 'Title',
    icon = 'checkout',
}){
    return (
        <div className="flex content-center p-2  mb-4">
            <img src={icons[icon]} alt={icon} className="w-20" />
            <h1 className="text-4xl justify-self-center self-center ml-4">{copy}</h1>
        </div>
    )
}