import Item from './Item';

function ItemList({
 items,
}) {
  return items.map((item)=>{
      return <Item key={item.title} {...item}></Item>
    })  
  }

export default ItemList;
