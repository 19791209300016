import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, StandaloneSearchBox, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '24rem',
};

const parseLocation = (location) => ({
  lat: location?.lat(),
  lng: location?.lng(),
});

function LocationPicker({
  onChange,
  initialCenter,
}) {

  const [center, setCenter] = useState();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const mapRef = useRef();
  const searchRef = useRef();
  const onMapLoad = ref => {
    setTimeout(() => setShowSearchBar(true), 500);
    mapRef.current = ref;
    if (initialCenter) {
      setCenter(initialCenter);
    }
    else if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((location) => {
        setCenter({
        lat: location.coords.latitude,
        lng: location.coords.longitude
      })
      onChange && onChange({
        lat: location.coords.latitude,
        lng: location.coords.longitude
      });
    })
    } else {
      // TODO: Use toast instead
      alert("Geolocation is not supported by this browser.");
    }
  }
  const onSearchLoad = ref => searchRef.current = ref;

  const onPlacesChanged = () => {
    const location = parseLocation(searchRef.current.getPlaces()[0].geometry.location);
    onChange && onChange(location);
    setCenter(location);
  };

  const boundsRadio = 0.1 // 10km

  //TODO: API key should be provided in props
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBSz1lX8vJRYd-z7MP8WbaP9yACm7XeqvQ"
      libraries={["places"]}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onMapLoad}
      >
        <StandaloneSearchBox
          onLoad={onSearchLoad}
          bounds={{
            north: center?.lat + boundsRadio,
            south: center?.lat - boundsRadio,
            east: center?.lng + boundsRadio,
            west: center?.lng - boundsRadio,
          }}
          onPlacesChanged={
            onPlacesChanged
          }
        >
          <input
            type="text"
            placeholder="Busca una ubicación"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `80%`,
              color: 'black',
              height: `64px`,
              position: 'absolute',
              opacity: showSearchBar ? '100%' : '0%',
              bottom: '32px',
              left: '32px',
              marginBottom: '2px',
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </StandaloneSearchBox>
        <Marker position={center} draggable={true} onDragEnd={(marker) => {
          const location = parseLocation(marker.latLng);
          onChange && onChange(location);
          mapRef.current.panTo(location);
        }}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default LocationPicker