import { toast } from 'react-toastify';
import {
  useParams,
  useHistory
} from "react-router-dom";
import Flow from '@las-drop-tools/el-flow';
import { createEvent } from './flows';
import useCreateEvent from './store';
import actions from './actions';

export default () => {
  const { setFlowData, flowData, resetFlowData } = useCreateEvent();
  const params = useParams();
  const history = useHistory();
  let step = createEvent.steps.find((step) => step?.id === params?.stepId);

  const toastOptions = {
    theme: 'dark',
    position: 'bottom-right'
  }

  return <Flow
        flow={createEvent}
        initialData={{}}
        step={step}
        flowData={flowData}
        onUpdate={(data) => setFlowData(data)}
        onStepChange={({ step, answers, errors }) => { history.push('/administrar/eventos/crear/'+step.id) }}
        actions={{
          submit: actions.create,
          success: ()=>{
            resetFlowData();
          },
          error: (content)=> {
            toast(content, toastOptions);
          },
        }}
      />
}