import { useEffect, useState } from 'react';
function Select({
    label,
    placeholder,
    sourceOptions,
    selected,
    actions,
    onChange,
}) {
    const [options, setOptions] = useState([selected])

    useEffect(async () => {
        const sourcedOptions = await sourceOptions(actions) || [];
        onChange(sourcedOptions[0]);
        setOptions(sourcedOptions);
    }, [])

    
    return options && <div className="form-control w-full">
        <label className="label">
            <span className="label-text">{label}</span>
        </label>
        <select
            className="select select-bordered placeholder-black bg-base-content text-black"
            defaultValue={selected?.label}
            onChange={(data) => {
                return onChange(options.find(({ label }) => label === data.target.value));
            }}
        >
            {!selected && <option disabled>{placeholder || label}</option>}
            {options?.map?.((option) => <option key={option?.value}>{option?.label}</option>)}
        </select>
    </div>
}
export default Select;
