import { api as makeApi } from '../../el-api';

const fetchAndProcessAudioStream = (url) => {
  return new Promise(async (resolve, reject) => {
    try {

      const response = await fetch(url);
      const reader = response.body.getReader();
      let chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
      }

      const audioData = new Blob(chunks);
      const readerBase32 = new FileReader();

      readerBase32.readAsDataURL(audioData);
      readerBase32.onloadend = function () {
        const base32Data = readerBase32.result.split(",")[1];
        // You can now store the base32 data in a variable or perform any further processing
        resolve(base32Data);
      };
    } catch (e) {
      reject(e);
    }
  });
}

const assetsApi = makeApi('asset');

const articleFields = [
  {
    type: 'file',
    label: 'Portada de artículo',
    key: 'cover',
    sourceAssets: async () => {
      const {data} = await assetsApi.findAll();
      return data;
    }
  },
  {
    type: 'select',
    label: 'Selecciona el segmento del artículo',
    key: 'segment',
    placeholder: 'Los Imperdibles',
    sourceOptions: async (actions) => {
      const { data } = await actions.getSegments()
      return data.map((segment) => ({ label: segment?.label, value: segment?.id })) || []
    }
  },
  {
    type: 'group',
    condition: (answers) => {
      return !!answers?.segment?.value;
    },
    fields: [
      {
        type: 'select-multi',
        label: 'Selecciona los eventos relacionados',
        key: 'events',
        sourceOptions: async (actions) => {
          const { data } = await actions.getEvents()
          return data.map((event) => ({ label: event?.title, value: event?.id })) || []
        },
        onCreate: async (actions, event) => {
          const { data } = await actions.createEvent({ title: event });
          return {
            label: data?.title, value: data?.id
          };
        },
        condition: (answers) => {
          return [1].includes(answers?.segment?.value);
        },
      },
      {
        type: 'select-multi',
        label: 'Selecciona los venues relacionados',
        key: 'places',
        sourceOptions: async (actions) => {
          const { data } = await actions.getVenues()
          return data.map((venue) => ({ label: venue?.label, value: venue?.id })) || []
        },
        onCreate: async (actions, venue) => {
          const { data } = await actions.upsertVenue({ label: venue.startsWith('@') ? venue : `@${venue}` });
          return {
            label: data?.label, value: data?.id
          };
        },
        condition: (answers) => {
          return [1].includes(answers?.segment.value);
        },
      },
      {
        type: 'select-multi',
        label: 'Selecciona los artistas relacionados',
        key: 'artists',
        sourceOptions: async (actions) => {
          const { data } = await actions.getArtists()
          return data.map((artist) => ({ label: artist?.handle, value: artist?.id })) || []
        },
        onCreate: async (actions, artist) => {

          const { data } = await actions.upsertArtist({ handle: artist.startsWith('@') ? artist : `@${artist}` });
          return {
            label: data?.handle, value: data?.id
          };
        },
        condition: (answers) => {
          return [1].includes(answers?.segment.value);
        },
      },
      {
        type: 'select-multi',
        label: 'Selecciona las canciones relacionadas',
        key: 'songs',
        sourceOptions: async (actions) => {
          const { data } = await actions.getSongs()
          return data.map((song) => ({ label: song?.title, value: song?.id })) || []
        },
        onCreate: async (actions, song, answers) => {
          const { data } = await actions.createSong({ title: song, artists: { connect: answers?.artists?.map?.((artist) => { return { id: artist.value } }) || [] } });
          return {
            label: data?.title, value: data?.id
          };
        },
        condition: (answers) => {
          return [3].includes(answers?.segment.value);
        },
      },
    ],
  },
  {
    type: 'input',
    placeholder: 'El evento',
    label: 'Título del artículo',
    key: 'title',
  },
  {
    type: 'input',
    placeholder: 'Promotor presenta: Artistas + Stands',
    label: 'Descripción',
    key: 'copy'
  },
  {
    type: 'editor',
    key: 'content',
  },
  {
    type: 'computed',
    key: 'generatedCopy',
    compute: async (answers) => {
      const prompt = `el evento se llama ${answers?.events ? answers?.events?.map?.(({ label }) => label)?.join(', ') : answers?.copy} y se llevará a cabo en ${answers?.places ? answers?.places?.map?.(({ label }) => label)?.join(', ') : answers?.copy} y contará con la participación de ${answers?.artists ? answers?.artists?.map?.(({ label }) => label)?.join(', ') : answers?.description}`
      return fetch('/api/services/chatgpt?prompt=' + prompt).then((res) => res.json())
    }
  },
  {
    type: 'computed',
    key: 'generatedTTS',
    condition: (answers) => {
      return !!answers?.generatedCopy?.[0];
    },
    compute: async (answers) => {
      const prompt = Array.isArray(answers.generatedCopy) ? answers?.generatedCopy?.[0] : answers?.generatedCopy;
      const audioStreamUrl = "/api/services/elevenlabs?prompt=" + encodeURIComponent(prompt.trim());
      return await fetchAndProcessAudioStream(audioStreamUrl);
    },
    partial: (field, value) => () => {
      return <>
        <h1>audio</h1>
        <audio controls="controls" autobuffer="autobuffer" >
          <source src={`data:audio/wav;base64,${value}`} />
        </audio>
      </>
    }
  },
  // {
  //   type: 'file',
  //   label: 'Video / Contenido referencia (preview, etc.)',
  //   key: 'media',
  // },
];

export const createArticle = {
  id: 'createArticle',
  fields: [
    ...articleFields,
    {
      type: 'button',
      copy: 'Crear',
      onClick: ({ answers, actions, error }) => {
        actions.createArticle(answers)
      }
    }
  ]
};

export const updateArticle = {
  id: 'updateArticle',
  fields: [
    ...articleFields,
    {
      type: 'button',
      copy: 'Actualizar',
      onClick: ({ answers, actions, error }) => {
        actions.updateArticle(answers)
      }
    }
  ]
};