const blackBg = '/el-drop-card.png';
const purpleBg = '/el-drop-card-s.png';

const providersMap = {
    matercard: '/payment-providers/mastercard_icon.svg',
    amex: '/payment-providers/amex_icon.svg',
    visa: '/payment-providers/visa.svg',
    default: '/payment-providers/visa.svg',
}

const backgroundsMap = {
    black: blackBg,
    purple: purpleBg,
    default: purpleBg,
}

function CreditCard({
    background,
    provider,
    card = {},
}) {
    
    const computedProps = {
        background: backgroundsMap[background] || backgroundsMap.default,
        provider: providersMap[provider] || providersMap.default,
    };
    
    return (
            <div className="w-auto xs:w-96 h-56 my-8 mx-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110">
                <img className="relative object-cover w-full h-full rounded-xl" src={computedProps?.background} />
                <div className="w-full px-8 absolute top-8">
                    <div className="flex justify-between">
                        <div className="">
                            <h1 className="font-light">
                                Name
                            </h1>
                            <p className="font-medium tracking-widest">
                            {card?.name || 'Name'}
                            </p>
                        </div>
                        <img className="w-14 h-14" src={computedProps?.provider} />
                    </div>
                    <div className="pt-1">
                        <h1 className="font-light">
                            Card Number
                        </h1>
                        <p className="font-medium tracking-more-wider">
                            {card?.number || 'XXXX  XXXX  XXXX  XXXX'}
                        </p>
                    </div>
                    <div className="pt-6 pr-6">
                        <div className="flex justify-between">
                            <div className="">
                                <h1 className="font-light text-xs text-xs">
                                    Expiry
                                </h1>
                                <p className="font-medium tracking-wider text-sm">
                                    {card?.expMonth || 'MM'}/{card?.expYear || 'YYYY'}
                                </p>
                            </div>

                            <div className="">
                                <h1 className="font-light text-xs">
                                    CVV
                                </h1>
                                <p className="font-bold tracking-more-wider text-sm">
                                    ···
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


    )
}

export default CreditCard;