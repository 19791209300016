import { useState, useEffect } from 'react';

const EventTickets = ({
    value,
    onChange,
}) => {

    const [tickets, setTickets] = useState();

    const defaultValue = [
        {
            qty: 10,
            description: 'General',
            price: 100
        }
    ]

    useEffect(()=>{
        setTickets(value || defaultValue);
        onChange(value || defaultValue);
    }, [])

    const handleChange = (index, key, value)=>{
        
        const updatedTickets = [
            ...tickets
        ];
        
        updatedTickets[index] = {
            ...(updatedTickets[index] ? updatedTickets[index] : {}),
            [key]: value,
        }

        setTickets(updatedTickets)
        onChange(updatedTickets)
    }

    const handleAddRow = ()=> {
        setTickets([...tickets, {}])
    }

    const handleRemove = (index)=> {
        const updatedTickets = [...tickets];
        updatedTickets.splice(index, 1);
        setTickets(updatedTickets);
    }

    return <>
        <div className="overflow-visible w-full">
            <table className="table w-full">
                <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Descripción</th>
                        <th>Precio (GTQ)</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets && !!tickets.length && tickets?.map?.(({
                        qty, description, price
                    }, index) => {
                        return <tr>
                            <td>
                                <input className="input w-18" type="number" defaultValue={qty} onBlur={(e)=> handleChange(index, 'qty', e.target.value) }></input>
                            </td>
                            <td className="w-full">
                                <input className="input w-full" type="text" defaultValue={description} onBlur={(e)=> handleChange(index, 'description', e.target.value) }></input>
                            </td>
                            <td> <input className="input w-18" type="number" defaultValue={price} onBlur={(e)=> handleChange(index, 'price', e.target.value) }></input></td>
                            <td onClick={()=> handleRemove(index)}>Eliminar</td>
                        </tr>
                    })
                    }
                </tbody>
            </table>
            <button className="btn btn-lg w-full btn-ghost mt-8" onClick={handleAddRow}>Agregar otro tipo de entrada</button>
        </div>
    </>
}

export default EventTickets;