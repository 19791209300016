import React, { useEffect, useState } from 'react';
import {
    Text,
    Input,
    CreditCard,
    ItemList,
    IconHeader,
    Checkbox,
    Confetti,
    Hero,
    Button,
    Row,
    Select,
    Month,
    Year,
    File,
    Editor,
    LocationPicker,
    SelectMulti,
    EventTickets
} from '@las-drop-tools/los-componentes';

function ErrorMessage({ message }) {
    return <p className='text-xs text-red-700'>{message}</p>;
};

function WithErrors({ error, children }) {
    return (
        <>
            {children}
            {error && <ErrorMessage message={error} />}
        </>
    )
}


const getDefaultPartial = (field, value, onChange, setValue) => () => 
<>
{Array.isArray(value) ?
onChange ? <ul  className='w-full h-auto'>{value.map((option) => <li className='h-auto'><textarea type="text" className='w-full h-24 bg-transparent py-12 px-8 my-4' defaultValue={option} onChange={({ target: { value } }) => onChange(value)} /></li>)}</ul> : <ul key={field?.key}>{value.map((option) => <li>{option}</li>)}</ul> : 
onChange ? <textarea className='w-full bg-transparent py-12 px-8' defaultValue={value} onBlur={({ target: { value } }) => { setValue(value); onChange(value)} } /> : <p key={field?.key}>{value}</p>}
</>
//rename to dynamic field
const ComputedField = ({ field, answers, onChange, partial = getDefaultPartial, loadingPartial = <p>Loading...</p>, autoload = false }) => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const handleLoad = async () => {
        setLoading(true);
        field?.compute(answers).then((data) => {
            setValue(data);
            onChange(data);
            setLoading(false);
        });
    }
    useEffect(() => {
        if (answers?.[field?.key]) {
            setValue(answers?.[field?.key]);
        } else {
            if (!loading) {
                if (field?.compute) {
                    if (autoload) {
                        handleLoad();
                    }
                } else {
                    setLoading(false);
                }
            }
        }
    }, [value]);
    const Partial = partial(field, value, onChange, setValue);
    return loading ?
        loadingPartial : <>
            {value && <Partial value={value} />}
            {field?.compute && <Button onClick={handleLoad} className="w-full">Load</Button>}
        </>
}

function FormField({
    field,
    error,
    setAnswers,
    answers,
    actions,
}) {

    const handleOnChange = (value) => setAnswers({ [field?.key]: value });

    switch (field?.type) {
        case "text":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Text key={field?.key} copy={field?.copy} textType={field?.textType} alignment={field?.alignment} size={field?.size} />
                </WithErrors>);
        case "input":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Input key={field?.key} label={field?.label} placeholder={field?.placeholder} inputType={field?.inputType} value={answers[field?.key]} onChange={handleOnChange} />
                </WithErrors>)
        case "select":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Select key={field?.key} label={field?.label} placeholder={field?.placeholder} selected={answers?.[field?.key]} onChange={handleOnChange} sourceOptions={field?.sourceOptions} actions={actions} />
                </WithErrors>)
        case "select-multi":
            return (
                <WithErrors key={field?.key} error={error}>
                    <SelectMulti key={field?.key} label={field?.label} sourceOptions={field?.sourceOptions} onChange={handleOnChange} actions={actions} onCreate={field?.onCreate} answers={answers} value={answers[field?.key]} />
                </WithErrors>)
        case "month":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Month key={field?.key} label={field?.label} placeholder={field?.placeholder} selected={field?.selected} onChange={handleOnChange} />
                </WithErrors>)
        case "year":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Year key={field?.key} label={field?.label} placeholder={field?.placeholder} selected={field?.selected} onChange={handleOnChange} />
                </WithErrors>)
        case "credit-card":
            return (
                <WithErrors key={field?.key} error={error}>
                    <CreditCard key={field?.key} card={field?.card(answers)} onChange={handleOnChange} />
                </WithErrors>)
        case "item-list":
            return (
                <WithErrors key={field?.key} error={error}>
                    <ItemList key={field?.key} items={field?.items} />
                </WithErrors>)
        case "icon-header":
            return (
                <WithErrors key={field?.key} error={error}>
                    <IconHeader key={field?.key} copy={field?.copy} icon={field?.icon} />
                </WithErrors>)
        case "checkbox":
            return (
                <WithErrors key={field?.key} error={error}>
                    <Checkbox key={field?.key} label={field?.label} onChange={handleOnChange} />
                </WithErrors>)
        case "hero":
            return <Hero key={field?.key} title={field?.title} content={field?.content} image={field?.image} button={field?.button} context={{
                answers,
                error,
                actions,
                setAnswers
            }} />
        case "button":
            return <Button key={field?.key} copy={field?.copy} onClick={() => field?.onClick({ answers, error, actions })} />
        case "confetti":
            return <Confetti key={field?.key} active={field?.active(answers)} />
        case "row":
            return <Row key={field?.key} field={field} context={{
                answers,
                error,
                setAnswers
            }} />
        case "file":
            return <File key={field?.key} imageURL={answers?.[field?.key]?.cdnUrl} onFileUploaded={handleOnChange} label={field?.label} sourceAssets={field?.sourceAssets} />
        case "editor":
            return <Editor key={field?.key} label={field?.label}
                // onChange={(data) => {
                //     handleOnChange(data); field?.customAction && field?.customAction({
                //         actions,
                //         answers,
                //     });
                // }} 
                onChange={handleOnChange}
                content={answers?.[field?.key]} />
        case "locationPicker":
            return <LocationPicker key={field?.key} onChange={handleOnChange} initialCenter={answers?.[field?.key]} />
        case "tickets":
            return <EventTickets key={field?.key} value={answers?.[field?.key]} onChange={handleOnChange} />
        case "group":
            // A field that has children fields, each children field should be a <FormField />
            return field?.fields.map((childField) => (!childField?.condition || childField?.condition?.(answers)) && <FormField key={childField?.key} field={childField} error={error} setAnswers={setAnswers} answers={answers} actions={actions} />)
        case "computed":
            // A field that displays (read-only, using a <span>) the result of a function that takes the answers as input and is held in the field's "compute" property
            return <ComputedField key={field?.key} field={field} answers={answers} onChange={handleOnChange} partial={field?.partial} />
        default:
            return <p key={field?.key}>unknown</p>
    }
}

export default FormField;
