import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    useParams,
    useHistory
} from "react-router-dom";
import Flow from '@las-drop-tools/el-flow';
import { createEvent } from './flows';
import useCreateEvent from './store';
import actions from './actions';
import { api as makeApi } from '../../el-api';

export default () => {
    const [loading, setLoading] = useState(false);
    const { setFlowData, flowData, resetFlowData } = useCreateEvent();
    const eventsApi = makeApi('event');
    const params = useParams();
    const history = useHistory();
    let step = createEvent.steps.find((step) => step?.id === params?.stepId);

    const toastOptions = {
        theme: 'dark',
        position: 'bottom-right'
    }

    useEffect(() => {
        setLoading(true);
        eventsApi.findOne(params.id, {
            include: {
                cover: true,
                location: true,
            }
        }).then(({ data }) => {
            const mappedEvent =  {
                ...data,
                placeName: data?.location?.label,
                location: data?.location?.location,
                cover: data?.cover?.metadata,
            }
            setFlowData({
                answers: mappedEvent,
            });
            setLoading(false);
        });
    }, []);

    return <>
    {!loading &&
        <Flow
            flow={createEvent}
            initialData={{
                // ...event,
            }}
            step={step}
            flowData={flowData}
            onUpdate={(data) => setFlowData(data)}
            onStepChange={({ step, answers, errors }) => { history.push(`/administrar/eventos/${params.id}/actualizar/${step.id}`) }}
            actions={{
                submit: actions.update,
                success: () => {
                    resetFlowData();
                },
                error: (content) => {
                    toast(content, toastOptions);
                },
            }}
        />}
    </>
}