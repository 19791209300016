import { Link } from "react-router-dom";
import { Icon, Button } from '@las-drop-tools/los-componentes'

export let event = {
    id: '1',
    title: 'Título del evento',
    type: {
        title: '',
        description: 'Titulo del evento, nombre del promotor, lugar.'
    },
    image: {
        title: 'Imagen principal',
        url: 'https://i.postimg.cc/C5My93P9/myheartswet-la-neoperreo-stash-krhtxnibwz.webp'
    },
    date: new Date(),
    place: {
        id: '1',
        name: 'Lugar',
    },
    tickets: [{
        title: 'Gratis' ,
        price: 0
    },{
        title: 'Gradas',
        price: '120'
    }]
}

const buyTickets = (eventId) => {
    console.log(eventId)
}

const Component = ({ event }) => {
    return (
        <div className="w-full m-auto flex flex-col justify-between p-10 md:p-20 border gap-24 xs:gap-28 md:gap-10 bg-cover relative" style={{
            backgroundImage: `url('${event?.cover?.url}')`
        }}>
            {/* <!-- cortina --> */}
            <div className="absolute top-0 left-0 w-full h-full" style={{
                backgroundImage: 'linear-gradient(180deg, hsl(var(--b1)) 25%, rgba(0,0,0,0) 100%)'
            }} />
            {/* <!-- controles --> */}
            <div className="flex flex-col xs:flex-row justify-between items-start xs:items-center gap-3 xs:gap-5 w-full zIndex">
                <div className="controls flex gap-5">
                    <Icon name='bookmark' className='mr-3 text-2xl hover:cursor-pointer hover:text-secondary'/>
                    <Icon name='share' className='mr-3 text-2xl hover:cursor-pointer hover:text-secondary'/>
                    <Icon name='local_activity' className='text-2xl hover:cursor-pointer hover:text-secondary animate__animated animate__heartBeat animate__delay-2s animate__repeat-3'/>
                </div>
                <h2 className='hidden lg:inline text-center'>Evento de la semana - { event?.date?.getDate?.() }/{ event?.date?.getMonth?.()+1 }</h2>
                <div className="badge badge-secondary badge-outline text-xs font-medium ">{event?.type?.title || 'Tipo'}</div>
            </div>
            {/* <!-- título --> */}
            <div className="flex flex-col gap-5 md:gap-0 lg:gap-5 zIndex sm:w-2/3">
                <Link to={`/eventos/${event?.id + '-' + (event?.slug || event?.title) || 'not-found'}`} className="" >
                    <h1 className="animate__animated animate__fadeInUp hover:text-base-content text-primary text-4xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-8xl font-black">{event?.title || ''}</h1>
                </Link>
                <p className="font-bold inline lg:hidden order-last md:order-first">
                    { event?.date?.getDate?.() }/{ event?.date?.getMonth?.()+1 }, <span className="capitalize">{event?.place?.name}</span>
                </p>
            </div>
            {/* <!-- divisor --> */}
            <div className="w-full flex sm:justify-end zIndex">
                <Link to={`/eventos/${event?.id + event?.slug || '4004'}`}>
                    <Button className='btn-primary' copy="Comprar entradas" color='primary'>
                        Comprar entradas
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default Component