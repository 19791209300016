import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useUser } from "@clerk/clerk-react";
import { useCopyToClipboard } from 'usehooks-ts'
import { useParams, useHistory } from "react-router-dom";
import { api as makeApi } from "../../el-api";
import { Card } from "./Grid";
import { Input, Button } from "../../los-componentes";

export default () => {
    const params = useParams();
    const history = useHistory();

    const user = useUser();
    const scope = user.isSignedIn ? 'root' : 'public';
    const invitationsApi = makeApi('eventInvitation', scope);
    const ticketsApi = makeApi('ticket', scope);

    const [invitation, setInvitation] = useState();
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState();
    const [invitationAccepted, setInvitationAccepted] = useState(false);
    const [email, setEmail] = useState({ email: '', emailConfirmation: '' });
    const [isRedeemed, setIsRedeemed] = useState(false);

    const [copiedValue, copyValue] = useCopyToClipboard();

    const handleCopyValue = () => {
        copyValue(`entradas.eldrop.club/eventos/invitacion/${invitation.id}`);
        toast('Enlace copiado');
    }

    useEffect(() => {
        invitationsApi.findOne(params?.id, {
            include: {
                event: {
                    include: {
                        cover: true,
                    }
                }
            }
        })
            .then(({ data }) => {
                setInvitation(data);
                if (scope === 'root' && data.accepted && !data.isOpen) {
                    // invitationsApi.update(data.id, {
                    //     data: {
                    //         isOpen: true,
                    //     }
                    // }).then((data) => console.log(data));
                    ticketsApi.findAll({
                        where: {
                            event: {
                                id: data.event.id
                            },
                            owner: {
                                // email: '{{user_email}}',
                                email: email.email,
                            },
                        }
                    }).then(({ data: tickets }) => {
                        setTicket(tickets[0]);
                    });
                }
            })
    }, []);

    const handleRedeem = () => {
        setIsRedeemed(true);
    };

    const handleBuy = () => {
        setLoading(true);
        const connectOwner = user.isSignedIn ? {
            owner: {
                connectOrCreate: {
                    where: {
                        email: '{{user_email}}',
                    },
                    create: {
                        name: '{{user_name}}',
                        email: '{{user_email}}',
                    }
                }
            },
        } : {};
        ticketsApi.create({
            data: {
                ...connectOwner,
                ownerEmail: email.email,
                event: {
                    connect: {
                        id: invitation.event.id
                    }
                },
                details: {
                    tbd: true,
                }
            }
        }).then(({ data }) => {

            setTicket(data);
            console.log('ticket', data)


            fetch('/api/services/sendgrid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    to: email.email,
                    subject: '¡Gracias por tu compra!',
                    ticket: data,
                    event: invitation.event,
                })
            }).then(() => {

                invitationsApi.update(invitation.id, {
                    data: {
                        accepted: true,
                    }
                }).then(() => {
                    setLoading(false);
                    setInvitation({
                        ...invitation,
                        accepted: true
                    });
                    setInvitationAccepted(true);
                });
            })
        });
    }

    // const handleResend = () => {
    //     setLoading(true);
    //     fetch('/api/services/sendgrid', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             to: user.user.primaryEmailAddress.emailAddress,
    //             subject: '¡Gracias por tu compra!',
    //             ticket: ticket,
    //             event: invitation?.event,
    //         })
    //     }).then(() => {
    //         toast.success('Ticket reenviado');
    //         setLoading(false);
    //     });
    // }
    const ticketRedeemed = <div className="w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center p-8">
        <h1 className="text-4xl my-4 font-bold text-white p-4 m-32 shadow-sm glass">¡Has obtenido tu entrada!</h1>
        <p className="text-xl text-center">Hemos enviado tu entrada a tu correo electrónico. Si no recibes el email, revisa tu bandeja de spam.</p>
        {/* {loading ? <span className="loading loading-ring loading-md p-8"></span> : <p className="link link-primary" onClick={handleResend}>reenviar correo</p>} */}
        <p className="text-xl text-center">¡Regístrate o inicia sesión con tu cuenta de eldrop para obtener entradas gratis para tus amigos!</p>
        <Button className='btn btn-primary mt-8' onClick={() => history.push(`/eventos/compartir/${params.id}`, { login: true })} >Iniciar Sesión</Button>
        {/* {scope === 'root' && <a href="/tickets" className="link link-secondary" >Revisa tu bandeja de tickets</a>} */}
    </div>

    const getTicket = isRedeemed ?
        <div className="w-full mx-4 max-w-none md:mx-0 md:max-w-sm h-auto p-8 bg-black bg-opacity-80 flex flex-col justify-center items-center shadow-sm glass rounded-xl">
            {invitationAccepted ? ticketRedeemed : <>
                <h1 className="text-4xl my-4 font-bold text-white  p-4">Ingresa el correo electrónico donde quieres recibir tu ticket.</h1>
                <Input type='email' placeholder='Correo electrónico' onChange={(value) => setEmail({ ...email, email: value })} />
                <Input type='email' placeholder='Confirma tu correo electrónico' onChange={(value) => setEmail({ ...email, emailConfirmation: value })} />
                {email.email !== email.emailConfirmation && !!email.email && <p className='text-red-500'>Los correos no coinciden</p>}
                <Button className='btn btn-primary mt-8' onClick={() => email.email !== email.emailConfirmation && !!email.email ? () => { } : handleBuy()} disabled={loading || !email.email || email.email !== email.emailConfirmation}>Obtener entrada</Button>
            </>
            }
        </div>
        :
        <Card event={invitation?.event} onBuy={handleRedeem} isLoading={loading} isMinimal={true} />



    const inviteFriends = <div className="w-full md:w-auto h-auto bg-black bg-opacity-50 flex flex-col justify-center items-center p-8 shadow-sm glass rounded-xl m-24">
        {/* <h1 className="text-4xl my-4 font-bold text-white p-4 m-32 ">¡Invita a tus amigos!</h1> */}
        <h1 className="text-2xl my-2 font-bold text-white">Puedes enviar el enlace a continuación para que tus amigos obtengan sus entradas:</h1>
        <p className="text-lg text-center text-primary
    bg-white bg-opacity-50 rounded-xl my-8 p-8 cursor-pointer
" onClick={handleCopyValue}>entradas.eldrop.club/eventos/invitacion/{invitation?.id}</p>
    </div>

    return invitation ?
        // || ( invitation.isOpen && !user?.isSignedIn )
        !invitation.accepted ?
            <div className="w-full h-full bg-cover bg-center flex justify-center items-center" style={{
                backgroundImage: `url(${invitation?.event?.cover?.url})`,
            }}>
                <div className="w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center" style={
                    { backdropFilter: 'blur(4px)', }
                }>
                    {getTicket}
                </div>
            </div >
            :
            <div className="w-full h-full background-red-100 bg-cover bg-center flex justify-center items-center" style={{
                backgroundImage: `url(${invitation?.event?.cover?.url})`,
            }}>
                <div className="w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center" style={
                    { backdropFilter: 'blur(4px)', }
                }>
                    {ticketRedeemed}
                    {/* {scope === 'root' && (invitation.isOpen ? : ticketRedeemedinviteFriends} */}

                </div>
            </div >

        : <div className="w-screen h-screen flex justify-center items-center">Loading...</div>
}