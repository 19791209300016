import { useState, useEffect } from 'react';
import Form from '@las-drop-tools/el-form';
import { api as makeApi } from '../../el-api';
import { updateSong } from './forms';
import { useParams } from 'react-router-dom';

export default  () => {

    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const songsApi = makeApi('song');
    const artistsApi = makeApi('artist');

    const { id } = useParams();

    useEffect(() => {
        const getSong = async () => {
            const { data: song } = await songsApi.findOne(id, { include: { artists: true, cover: true } });
            setLoading(false);
            if (song) {
                song.artists = song?.artists.map((artist) => ({ label: artist?.handle, value: artist?.id }));
                song.cover = song?.cover?.metadata;
                setFormData(song);
            }
        }
        getSong();
    }, []);

    return formData ? <>
        <div className='py-8 px-8 lg:px-72 w-full'>
            <Form form={updateSong} initialData={formData || {}} onChange={(x) => { const z = { ...formData, ...x?.answers }; console.log('newdata', z); setFormData(z) }} answers={formData} actions={{
                updateSong: async (answers) => {
                    songsApi.update(id, {
                        data: {
                            artists: {
                                set: [],
                                connectOrCreate: answers.artists.map((artist) => ({
                                    where: {
                                        id: artist?.value,
                                    },
                                    create: {
                                        handle: artist?.label,
                                    }
                                })),
                            },
                            title: answers?.title,
                            cover: {
                                connectOrCreate: {
                                    where: {
                                        url: answers?.cover?.cdnUrl,
                                    },
                                    create: {
                                        url: answers?.cover?.cdnUrl,
                                        metadata: answers?.cover,
                                        owner: {
                                            connectOrCreate: {
                                                where: {
                                                    email: '{{user_email}}',
                                                },
                                                create: {
                                                    name: '{{user_name}}',
                                                    email: '{{user_email}}',
                                                }
                                            }
                                        },
                                    }
                                }
                            },
                        }
                    }, {
                        include: {
                            artists: true,
                            cover: true,
                        }
                    })
                },
                getArtists: () => artistsApi.findAll({}),
                upsertArtist: (artist) => artistsApi.upsert({
                    where: artist,
                    update: artist,
                    create: artist
                })
            }}
            />
        </div>
    </> : loading ? <div>Loading...</div> : <div>Song not found</div>
}