import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api as makeApi } from '../../el-api';
import { Icon, IconHeader, Toggle } from '../../los-componentes';
import { toast } from 'react-toastify';
import Canvas from './canvas';
import fg from './assets/fg.png';
import bg from './assets/bg.png';

const camelCaseToSpaces = (str) => str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

const Journalism = () => {
    const articlesApi = makeApi('article');
    const [articles, setArticles] = useState();

    const history = useHistory();

    useEffect(() => {
        articlesApi.findAll({
            include: {
                cover: true,
            },
        }).then(({ data }) => {
            setArticles(data)
        });



    }, []);

    const fields = {
        id: {
            label: 'ID',
            component: ({ value }) => <span>{value}</span>,
        },
        // cover: {
        //     label: 'Cover',
        //     component: ({ value: { url } }) => <>
        //         <div class="max-w-xs m-auto p-2">
        //             <img class="w-full object-cover " src={url} alt="Image" />
        //         </div>
        //     </>
        // },
       
        title: {
            label: 'Title',
            component: ({ value }) => <span>{value}</span>,
        },
        isPublished: {
            label: 'Published',
            component: ({ value, id }) => <Toggle value={value} onChange={() => {
                id && handlePublishToggle(id, !value);
            }} />,
        },
        createdAt: {
            label: 'Created At',
            component: ({ value }) => <span>{value}</span>,
        },
        preview: {
            label: 'Preview',
            initialValue: ({ title, copy, cover: { url }, generatedTTS, generatedCopy }) => ({ header: title, subheader: copy, url, generatedTTS, generatedCopy }),
            component: ({ value }) => {
                const template = {
                    backgroundImageUrl: bg,
                    foregroundImageUrl: fg,
                };
                const header = value?.header || 'Header Text';
                const subheader = value?.subheader || 'Subheader Text';
                const imageUrl = value?.url || 'https://static-cse.canva.com/blob/1064933/1600w-8iSVF3m00jk.jpg';
                const audio = value?.generatedTTS;
                return <>
                    <Canvas template={template} header={header} subheader={subheader} imageUrl={imageUrl} />
                    <p className='w-full max-w-xs whitespace-normal text-center'>{value.generatedCopy}</p>
                    {audio && <audio controls="controls">
                        <source src={`data:audio/wav;base64,${audio}`} />
                    </audio>}
                </>
            },
        },
        // generatedCopy: {
        //     label: 'Generated Copy',
        //     component: ({ value }) => Array.isArray(value) ? <ul className=''>{value?.map((copy) => <li>{copy}</li>)}</ul> : <span>{value}</span>,
        // },
    }

    const handleDelete = (id) => {
        articlesApi.destroy(id).then(({ status, error }) => {
            if (status !== 200) {
                toast.error(error);
            } else {
                setArticles(articles.filter((article) => article.id !== id));
                toast.success('Article deleted successfully');
            }
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    const handleEdit = (id) => {
        history.push(`/news/update/${id}`);
    };

    const handlePublishToggle = (id, checked) => {
        setArticles(articles.map((article) => article.id === id ? { ...article, isPublished: checked } : article));
        articlesApi.update(id, { data: { isPublished: checked } }).then(({ status, error }) => {
            if (status !== 200) {
                toast.error(error);
                setArticles(articles.map((article) => article.id === id ? { ...article, isPublished: !checked } : article));
            } else {
                toast.success('Article updated successfully');

            }
        }).catch((err) => {
            toast.error(err.message);
        });
    };


    return <>
        <div className='py-8 px-8 lg:px-24 w-full'>
            <h1 className='text-7xl font-bold uppercase py-8'>Gestiona tus artículos</h1>
            <div className="overflow-x-overflow">
                {/* // The following div requires to align its child to the right, flex-end */}
                <div className="p-4 
                flex flex-row-reverse
                ">
                        <button className="btn btn-outline" onClick={() => history.push('/news/create')}>Create Article</button>
                    <div className="w-full btn-group btn-group-vertical lg:btn-group-horizontal">
                        <button className="btn btn-active">Los lanzamientos más fresh</button>
                        <button className="btn">Los eventos imperdibles</button>
                        <button className="btn">el TBT</button>
                        <button className="btn">el recap</button>
                        <button className="btn">el foco</button>
                        <button className="btn">todos</button>
                    </div>
                </div>
                <table className="table w-full my-8">
                    <thead>
                        <tr>
                            {articles && Object.keys(fields).map((key) => (<th key={key}>{camelCaseToSpaces(key)}</th>))}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles && articles?.sort?.(
                            // sort by createdAt ascending, then by title descending, then by isPublished ascending
                            (a, b) => a.createdAt.localeCompare(b.createdAt) || b.title.localeCompare(a.title)
                        )?.map?.((article, index) => {
                            return (
                                <tr key={article.id}>
                                    {article && Object.entries(fields).map(([key, { component: Component, initialValue }]) => {
                                        return <td className="max-w-xs" key={key}><Component value={initialValue ? initialValue(article) : article[key]} id={article?.id} /></td>
                                    })}
                                    <td>
                                        <div className="dropdown dropdown-end">
                                            <label tabIndex={0} className="btn btn-circle btn-ghost m-1"><Icon name="more_vert" className="text-xl" /></label>
                                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-secondary rounded-box w-52">
                                                <li onClick={() => handleEdit(article.id)} ><a><Icon name="edit" className='text-xl' />Edit</a></li>
                                                <li onClick={() => handleDelete(article.id)}><a><Icon name="delete" className='text-xl' />Delete</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

export default Journalism;